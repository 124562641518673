import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { add, formatISO } from 'date-fns';
import _ from 'lodash';
import {GenericObject} from "../../@types/common";
import {getDateInTimeStamp, getSafeValue} from '../../utils';
import {
    addProductToOrderEffect, addSerialsOfOrderItems, checkHasNewOrder,
    deleteItemsOrderDetails, getClosedOrderDetail, getClosedOrderList, getOpenOrderList, getOrderOpenDetail, getOrderPaymentList, getOrderStatusList,
    searchProductToAddOrder,
    updateOrderEffect, updateQtyOrderDetail
} from "./effects";
import moment from "moment";


const initState = {
    isLoading: false,

    checkNewOrder: {
        hasNewOpenOrder: false,
        timeZero: undefined,
    } as GenericObject,
    openOrderList: {
        isLoading: false,
        data: [] as GenericObject,
        pagination: {}
    } as GenericObject,
    closedOrderList: {
        isLoading: false,
        data: [] as GenericObject,
        pagination: {}
    } as GenericObject,
    orderStatusList: {
        isLoading: false,
        data: [] as GenericObject
    } as GenericObject,
    orderPaymentList: {
        isLoading: false,
        data: [] as GenericObject
    } as GenericObject,
    orderOpenDetail: {
        isLoading: false,
        data: {} as GenericObject
    } as GenericObject,
    closedOrderDetail: {
        isLoading: false,
        data: {} as GenericObject
    } as GenericObject,
    productSearchToAdd: {
        data: [],
        pagination: {},
        isLoading: false,
        isFull: false
    } as GenericObject,
    addProductToOrder: {
        isLoading: false,
    } as GenericObject,
    serialsOfOrderItems: {
        isLoading: false,
    } as GenericObject,
    updateQtyOrder: {
        isLoading: false,
    } as GenericObject
};
const orderSlice = createSlice({
  name: 'order',
  initialState: initState,
    reducers: {
        clearState: (state) => {
            state.isLoading = false;
            return state;
        },
        clearNewOrderNofi: (state) => {
            state.checkNewOrder.hasNewOpenOrder = false;
            return state;
        }
    },

  extraReducers: builder => {
    builder
      .addCase(getOpenOrderList.fulfilled, (state, action) => {
          state.openOrderList.isLoading = false;
          if (!action.payload.error){

              // let timeZone = action.payload?.timeZone || "GMT";
              let timeZero = localStorage.getItem('timeZero')
                  ? localStorage.getItem('timeZero') : '';

              let timeZero2 = timeZero && moment(parseInt(timeZero)).endOf('minute');

              const currentDate = getDateInTimeStamp(new Date());

              const orderList = getSafeValue(action.payload, 'orders', []);
              if (!_.isEmpty(orderList)){
                  const orderListNew = _.map(orderList, (item, index)=> {

                      let timestampInMilisecond = 1000*parseInt(item.createdTime);
                      let createDate = moment(timestampInMilisecond).endOf('second');

                      if (timeZero2 && createDate > timeZero2){
                          return {...item, isNewOrder: true};
                      } else {
                          return item;
                      }
                  });
                  state.openOrderList.data = orderListNew || [];
                  localStorage.setItem('timeZero', currentDate);
                  state.checkNewOrder.hasNewOpenOrder = false;
              } else {
                state.openOrderList.data = [];
              }
              state.openOrderList.pagination = action.payload?.pagination || {};
          }
      })
      .addCase(getOpenOrderList.pending, state => {
        state.openOrderList.isLoading = true;
          state.checkNewOrder.hasNewOpenOrder = false;
      })
      .addCase(getOpenOrderList.rejected, (state, action) => {
          state.openOrderList.isLoading = false;
      });

      builder
          .addCase(checkHasNewOrder.fulfilled, (state, action) => {
              if (!action.payload.error){

                  // let timeZone = action.payload?.timeZone || "GMT";
                  let timeZero = localStorage.getItem('timeZero')
                      ? localStorage.getItem('timeZero') : '';

                  let timeZero2 = timeZero && moment(parseInt(timeZero)).endOf('minute');

                  const orderList = getSafeValue(action.payload, 'orders', []);
                  if (!_.isEmpty(orderList)){
                      _.map(orderList, (item, index)=> {

                          let timestampInMilisecond = 1000*parseInt(item.createdTime);
                          let createDate = moment(timestampInMilisecond).endOf('minute');

                          if (timeZero2 && createDate > timeZero2){
                              state.checkNewOrder.hasNewOpenOrder = true;
                              return;
                          }
                      });
                  }
              }
          })
          .addCase(checkHasNewOrder.pending, state => {
              // state.hasNewOpenOrder = false;
          })
          .addCase(checkHasNewOrder.rejected, (state, action) => {
              // state.hasNewOpenOrder = false;
          });

      builder
          .addCase(getClosedOrderList.fulfilled, (state, action) => {
              state.closedOrderList.isLoading = false;
              if (!action.payload.error){
                  state.closedOrderList.data = action.payload?.orders || [];
                  state.closedOrderList.pagination = action.payload?.pagination || {};
              }
          })
          .addCase(getClosedOrderList.pending, state => {
              state.closedOrderList.isLoading = true;
          })
          .addCase(getClosedOrderList.rejected, (state, action) => {
              state.closedOrderList.isLoading = false;
          });

      builder.addCase(getOrderStatusList.fulfilled, (state, action) => {
            state.orderStatusList.isLoading = false;
            if (!action.payload.error){
                state.orderStatusList.data = action.payload?.status || [];
            }
        })
        .addCase(getOrderStatusList.pending, state => {
            state.orderStatusList.isLoading = true;
        })
        .addCase(getOrderStatusList.rejected, (state, action) => {
            state.orderStatusList.isLoading = false;
        });

      builder.addCase(getOrderPaymentList.fulfilled, (state, action) => {
            state.orderPaymentList.isLoading = false;
            if (!action.payload.error){
                state.orderPaymentList.data = action.payload?.paymentStatuses;
            }
        })
        .addCase(getOrderPaymentList.pending, state => {
            state.orderPaymentList.isLoading = true;
        })
        .addCase(getOrderPaymentList.rejected, (state, action) => {
            state.orderPaymentList.isLoading = false;
        });

      builder.addCase(getOrderOpenDetail.fulfilled, (state, action) => {
            state.orderOpenDetail.isLoading = false;
            if (!action.payload.error){
                state.orderOpenDetail.data = action.payload?.order || {};
            }
        })
        .addCase(getOrderOpenDetail.pending, state => {
            state.orderOpenDetail.isLoading = true;
        })
        .addCase(getOrderOpenDetail.rejected, (state, action) => {
            state.orderOpenDetail.isLoading = false;
        });

      builder.addCase(getClosedOrderDetail.fulfilled, (state, action) => {
          state.closedOrderDetail.isLoading = false;
          if (!action.payload.error){
              state.closedOrderDetail.data = action.payload?.order || {};
          }
      })
          .addCase(getClosedOrderDetail.pending, state => {
              state.closedOrderDetail.isLoading = true;
          })
          .addCase(getClosedOrderDetail.rejected, (state, action) => {
              state.closedOrderDetail.isLoading = false;
          });

      builder.addCase(deleteItemsOrderDetails.fulfilled, (state, action) => {
            state.orderOpenDetail.isLoading = false;
            if (!action.payload.error){
                state.orderOpenDetail.data = action.payload?.order || {};
            }
        })
        .addCase(deleteItemsOrderDetails.pending, state => {
            state.orderOpenDetail.isLoading = true;
        })
        .addCase(deleteItemsOrderDetails.rejected, (state, action) => {
            state.orderOpenDetail.isLoading = false;
        })
        // .addCase(updateOrderEffect.fulfilled, (state, action) => {
        //     state.openOrderList.isLoading = false;
        //     if (!action.payload.error){
        //         // const newList = [...state.openOrderList.data, ...action.payload?.orders];
        //         // const newListUniq = _.reverse(_.uniqBy(_.reverse(newList), 'id'));
        //         const newListUniq = _.uniqBy([...action.payload?.orders, ...state.openOrderList.data], 'id');
        //         console.log('all',[...action.payload?.orders, ...state.openOrderList.data]);
        //         console.log('newListUniq',newListUniq);
        //         state.openOrderList.data = newListUniq || [];
        //     }
        // })
        // .addCase(updateOrderEffect.pending, state => {
        //     state.openOrderList.isLoading = true;
        // })
        // .addCase(updateOrderEffect.rejected, (state, action) => {
        //     state.openOrderList.isLoading = false;
        // })
    ;
      builder.addCase(searchProductToAddOrder.fulfilled, (state, action) => {
          if (!action.payload.error){
              let resultList = getSafeValue(action.payload, 'products', []);
              state.productSearchToAdd.data =
                  action.meta?.arg?.pageNumber == 1 ? resultList : [...state.productSearchToAdd.data, ...resultList];
              state.productSearchToAdd.pagination = action.payload?.pagination || {};
              state.productSearchToAdd.isLoading = false;
              state.productSearchToAdd.isFull = action.payload?.pagination?.pageNumber == action.payload?.pagination?.totalPages;
          }
      })
      .addCase(searchProductToAddOrder.pending, (state, action) => {
          state.productSearchToAdd.isLoading = true;
      })
      .addCase(searchProductToAddOrder.rejected, (state, action) => {
          state.productSearchToAdd.isLoading = false;
      });
      builder.addCase(addProductToOrderEffect.fulfilled, (state, action) => {
          state.addProductToOrder.isLoading = false;
          if (!action.payload.error){
              state.orderOpenDetail.data = action.payload?.order || {};
          }
      })
      .addCase(addProductToOrderEffect.pending, state => {
          state.addProductToOrder.isLoading = true;
      })
      .addCase(addProductToOrderEffect.rejected, (state, action) => {
              state.addProductToOrder.isLoading = false;
          });

      builder
          .addCase(addSerialsOfOrderItems.fulfilled, (state, action) => {
              state.serialsOfOrderItems.isLoading = false;
          })
          .addCase(addSerialsOfOrderItems.pending, state => {
              state.serialsOfOrderItems.isLoading = true;
          })
          .addCase(addSerialsOfOrderItems.rejected, (state, action) => {
              state.serialsOfOrderItems.isLoading = false;
          });
      builder
          .addCase(updateQtyOrderDetail.fulfilled, (state, action) => {
              state.updateQtyOrder.isLoading = false;
          })
          .addCase(updateQtyOrderDetail.pending, state => {
              state.updateQtyOrder.isLoading = true;
          })
          .addCase(updateQtyOrderDetail.rejected, (state, action) => {
              state.updateQtyOrder.isLoading = false;
          });
  },
});

export const {clearState, clearNewOrderNofi} = orderSlice.actions;
export default orderSlice.reducer;
