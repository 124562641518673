import { apiInstance } from '../../../services/api';
import ApiConstants from '../../../constants/endpoint';
import util from 'util';
import qs from 'query-string';

// services

export const getOpenOrderListAPI = async (params: any) => {
  const value = {
    appKey: params.appKey || '60d2b91b90de1200015b5c4b',
    pageNumber: params.pageNumber || 1,
    pageSize: params.pageSize || 20,
    orderStatus: params.orderStatus || [],
    orderId: params.orderId || '',
    fromDate: params.fromDate || '',
    toDate: params.toDate || '',
    shopName: params.shopName || '',
    sortBy: params.sortBy || 'createdDate',
    sortDirection: params.sortDirection || 'desc',
    paymentStatus: params.paymentStatus || '',
    orderType: 'open',
  };

  return (
    await apiInstance.get(ApiConstants.GET_OPEN_ORDER_LIST, {
      params: value, // get, delete => use "params", post, put, delete dùng "data"
      paramsSerializer: queryParams => {
        const rs = qs.stringify(queryParams, { arrayFormat: 'comma' });
        return rs;
      },
    })
  ).data;
};

export const getClosedOrderListAPI = async (params: any) => {
  const value = {
    appKey: params.appKey,
    pageNumber: params.pageNumber || 1,
    pageSize: params.pageSize || 20,
    orderStatus: params.orderStatus || [],
    orderId: params.orderId || '',
    fromDate: params.fromDate || '',
    toDate: params.toDate || '',
    shopName: params.shopName || '',
    sortBy: params.sortBy || 'createdDate',
    sortDirection: params.sortDirection || 'desc',
    paymentStatus: params.paymentStatus || '',
    orderType: 'closed',
  };

  return (
    await apiInstance.get(ApiConstants.GET_CLOSED_ORDER_LIST, {
      params: value, // get, delete => use "params", post, put, delete dùng "data"
      paramsSerializer: queryParams => {
        const rs = qs.stringify(queryParams, { arrayFormat: 'comma' });
        return rs;
      },
    })
  ).data;
};

export const getOrderStatusListAPI = async (params: any) => {
  const url = util.format(
    ApiConstants.GET_ORDER_STATUS_LIST,
    params.appKey || '60d2b91b90de1200015b5c4b' // appKey site test https://brandworx-pts1751.jmango.vn/
  );
  return (await apiInstance.get(url)).data;
};

export const getOrderPaymentListAPI = async (params: any) => {
  const url = util.format(
    ApiConstants.GET_ORDER_PAYMENT_LIST,
    params.appKey || '60d2b91b90de1200015b5c4b' // appKey site test https://brandworx-pts1751.jmango.vn/
  );
  return (await apiInstance.get(url)).data;
};

export const updateOrderAPI = async (params: any) => {
  const url = util.format(ApiConstants.UPDATE_ORDER, params.appKey);
  const result = await apiInstance.put(url, params);

  return result;
};
export const updateDeliveryDateAPI = async (params: any) => {
  const url = util.format(ApiConstants.UPDATE_DELIVERY_DATE, params.orderIds);
  const result = await apiInstance.put(url, params);

  return result;
};

export const updateOrderReference = async (params: any) => {
  const url = util.format(ApiConstants.UPDATE_ORDER_REFERENCE, params.orderIds);
  const result = await apiInstance.put(url, params);

  return result;
};

export const getOrderOpenDetailAPI = async (params: any) => {
  const url = util.format(
    ApiConstants.GET_ORDER_OPEN_DETAIL,
    params.orderId,
    params.appKey || '60d2b91b90de1200015b5c4b' // appKey site test https://brandworx-pts1751.jmango.vn/
  );
  return await apiInstance.get(url);
};

export const getClosedOrderDetailAPI = async (params: any) => {
  const url = util.format(
    ApiConstants.GET_CLOSED_ORDER_DETAIL,
    params.orderId,
    params.appKey // appKey site test https://brandworx-pts1751.jmango.vn/
  );
  return await apiInstance.get(url);
};

export const updateQtyOrderDetailAPI = async (params: any) => {
  const url = util.format(ApiConstants.UPDATE_QTY_ITEM_ORDER_DETAIL, params.orderId);

  const paramBody = {
    appKey: params.appKey,
    items: params.items,
  };
  const result = await apiInstance.put(url, paramBody);

  return result;
};

export const deleteItemsOrderDetailsAPI = async (params: any) => {
  const url = util.format(ApiConstants.DELETE_PRODUCT_ORDER_DETAIL, params.orderId);

  const value = {
    appKey: params.appKey,
    orderItemId: params.orderItemId,
  };

  const result = await apiInstance.delete(url, {
    params: value, // get, delete => use "params", post, put, delete dùng "data"
    paramsSerializer: queryParams => {
      const rs = qs.stringify(queryParams, { arrayFormat: 'comma' });
      return rs;
    },
  });

  return result;
};

export const searchProductToAddOrderAPI = async (params: any) => {
  const url = util.format(ApiConstants.SEARCH_PRODUCT_TO_ADD_ORDER, params.orderId);
  const value = {
    appKey: params.appKey,
    query: params.query || '',
    currencyId: params.currencyId || '',
    pageNumber: params.pageNumber || 1,
    pageSize: params.pageSize || 20,
  };

  return await apiInstance.get(url, {
    params: value,
    paramsSerializer: queryParams => {
      const rs = qs.stringify(queryParams, { arrayFormat: 'comma' });
      return rs;
    },
  });
};

export const addProductToOrderAPI = async (params: any) => {
  const url = util.format(ApiConstants.ADD_PRODUCT_TO_ORDER, params.orderId);

  const paramBody = {
    appKey: params.appKey,
    product: params.product,
  };
  const result = await apiInstance.post(url, paramBody);

  return result;
};

export const addSerialsOfOrderItemsAPI = async (params: any) => {
  const url = util.format(ApiConstants.ADD_SERIALS_OF_ORDER_ITEMS, params.orderId);

  const paramBody = {
    appKey: params.appKey,
    items: params.items,
  };
  const result = await apiInstance.post(url, paramBody);

  return result;
};
