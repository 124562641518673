import { createAsyncThunk } from '@reduxjs/toolkit';
import OrderServices from '../services';

export const getOpenOrderList = createAsyncThunk('order/getOpenOrderList', async (params: any) => {
  return OrderServices.getOpenOrderListAPI(params);
});

export const checkHasNewOrder = createAsyncThunk('order/checkHasNewOrder', async (params: any) => {
  return OrderServices.getOpenOrderListAPI(params);
});

export const getClosedOrderList = createAsyncThunk('order/getClosedOrderList', async (params: any) => {
  return OrderServices.getClosedOrderListAPI(params);
});

export const getOrderStatusList = createAsyncThunk('order/getOrderStatusList', async (params: any) => {
  return OrderServices.getOrderStatusListAPI(params);
});

export const getOrderPaymentList = createAsyncThunk('order/getOrderPaymentList', async (params: any) => {
  return OrderServices.getOrderPaymentListAPI(params);
});

export const updateOrderEffect = createAsyncThunk('order/updateOrder', async (params: any, { rejectWithValue }) => {
  try {
    const result = await OrderServices.updateOrderAPI(params);
    return result.data;
  } catch (error) {
    return rejectWithValue({ ...error.response.data });
  }
});
export const updateDeliveryDateEffect = createAsyncThunk(
  'order/updateDeliveryDateEffect',
  async (params: any, { rejectWithValue }) => {
    try {
      const result = await OrderServices.updateDeliveryDateAPI(params);
      return result.data;
    } catch (error) {
      return rejectWithValue({ ...error.response.data });
    }
  }
);

export const updateOrderReferenceEffect = createAsyncThunk(
  'order/updateOrderReference',
  async (params: any, { rejectWithValue }) => {
    try {
      const result = await OrderServices.updateOrderReference(params);
      return result.data;
    } catch (error) {
      return rejectWithValue({ ...error.response.data });
    }
  }
);

export const getOrderOpenDetail = createAsyncThunk(
  'order/getOrderOpenDetail',
  async (params: any, { rejectWithValue }) => {
    try {
      const result = await OrderServices.getOrderOpenDetailAPI(params);
      return result.data;
    } catch (error) {
      return rejectWithValue({ ...error.response.data });
    }
  }
);

export const getClosedOrderDetail = createAsyncThunk(
  'order/getClosedOrderDetail',
  async (params: any, { rejectWithValue }) => {
    try {
      const result = await OrderServices.getClosedOrderDetailAPI(params);
      return result.data;
    } catch (error) {
      return rejectWithValue({ ...error.response.data });
    }
  }
);

export const updateQtyOrderDetail = createAsyncThunk(
  'order/updateQtyOrderDetail',
  async (params: any, { rejectWithValue }) => {
    try {
      const result = await OrderServices.updateQtyOrderDetailAPI(params);
      return result.data;
    } catch (error) {
      return rejectWithValue({ ...error.response.data });
    }
  }
);

export const deleteItemsOrderDetails = createAsyncThunk(
  'order/deleteItemsOrderDetails',
  async (params: any, { rejectWithValue }) => {
    try {
      const result = await OrderServices.deleteItemsOrderDetailsAPI(params);
      return result.data;
    } catch (error) {
      return rejectWithValue({ ...error.response.data });
    }
  }
);

export const searchProductToAddOrder = createAsyncThunk(
  'order/searchProductToAddOrder',
  async (params: any, { rejectWithValue }) => {
    try {
      const result = await OrderServices.searchProductToAddOrderAPI(params);
      return result.data;
    } catch (error) {
      return rejectWithValue({ ...error.response.data });
    }
  }
);

export const addProductToOrderEffect = createAsyncThunk(
  'order/addProductToOrder',
  async (params: any, { rejectWithValue }) => {
    try {
      const result = await OrderServices.addProductToOrderAPI(params);
      return result.data;
    } catch (error) {
      return rejectWithValue({ ...error.response.data });
    }
  }
);

export const addSerialsOfOrderItems = createAsyncThunk(
  'order/addSerialsOfOrderItems',
  async (params: any, { rejectWithValue }) => {
    try {
      const result = await OrderServices.addSerialsOfOrderItemsAPI(params);
      return result.data;
    } catch (error) {
      return rejectWithValue({ ...error.response.data });
    }
  }
);
