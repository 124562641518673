import React, {useEffect, useState} from "react";

import {DesktopOutlined, UserOutlined, CaretDownFilled, EyeTwoTone} from "@ant-design/icons";
import {useSpring, animated} from "react-spring";
import { format, fromUnixTime } from 'date-fns';


import "./style.scss";
import OrderClose from "./CloseOrder";
import {useHistory, useLocation} from "react-router";
import {Tabs} from "antd";
import OrderOpen from "./OrderOpen";
import {getDateInTimeStamp} from "../../utils";

const { TabPane } = Tabs;

const Order = () => {
    const location = useLocation();
    const history = useHistory();

    const [currentTab, setCurrentTab] = useState<any>('');

    useEffect(() => {
        const paramsLocation = location.search;
        const params = new URLSearchParams(paramsLocation);
        const tab = params.get('tab');
        if (tab) {
            setCurrentTab(tab);
        } else {
            setCurrentTab('1');
        }
    }, [location]);

    const callback = key => {

        const currentDate = getDateInTimeStamp(new Date());
        localStorage.setItem('timeZero', currentDate);

        if (key == 1) {
            history.push(`${location.pathname}`);
        } else {
            history.push(`${location.pathname}?tab=${key}`);
        }
        setCurrentTab(key);
    };

    return (
        <Tabs activeKey={currentTab}
              onChange={callback}
              destroyInactiveTabPane={true}
              animated={true}>
            <TabPane tab="Open Orders" key="1">
                <OrderOpen/>
            </TabPane>
            <TabPane tab="Closed Orders" key="2">
                <OrderClose/>
            </TabPane>
        </Tabs>
    );
};

export default Order;
