import React, { useEffect, useState } from 'react';
import { Image, Typography, Row, Col, Table, notification, Button, Popconfirm } from 'antd';
import {
  DesktopOutlined,
  UserOutlined,
  CaretDownFilled,
  EyeTwoTone,
  CheckCircleFilled,
  PrinterOutlined,
  LeftOutlined,
} from '@ant-design/icons';
import { useSpring, animated } from 'react-spring';
import moment from 'moment';
import { format, fromUnixTime } from 'date-fns';
import './style.scss';
import { useSelector } from 'react-redux';
import { ColumnsType } from 'antd/es/table';
import _ from 'lodash';
import { useAppDispatch } from '../../../../core/app.store';
import { getDistributorSelector } from '../../../../core/admin/selectors';
import { useParams } from 'react-router-dom';
import { getClosedOrderDetail, getOrderStatusList, updateOrderEffect } from '../../../../core/order/effects';
import { getClosedOrderDetailSelector, getOrderStatusSelector } from '../../../../core/order/selectors';
import { convertCurrency, getAvailableStatusOrder, getStatusOrderByID, safeParseDate } from '../../../../utils';
import { baseUrl } from '../../../../services/urlAPI';
import Address from '../../../../components/Address';
import DriverInfo from '../../../../components/DriverInfo';
import defaultImage from '../../../../assets/images/defaultImage.png';
import { ConstOrderStatus } from '../../../../constants';
import * as RouteConst from '../../../../constants/RouteConst';
import { useHistory } from 'react-router';
import icnReOpen from '../../../../assets/images/icnReOpenOrder.svg';
import ProductSerial from '../../../../components/ProductSerial';
import ScrollToTop from '../../../../components/ScrollToTop';

const { Title } = Typography;

const pagination = {
  pageSize: 20,
  pageNumber: 1,
};

const ClosedOrderDetail = () => {
  const fadeInUp = {
    delay: 100,
    from: {
      opacity: 0,
      translateY: 80,
    },
    to: {
      opacity: 1,
      translateY: 0,
    },
  };
  const fadeInUp1 = useSpring(fadeInUp);
  const fadeInUp2 = useSpring({ ...fadeInUp, delay: 300 });

  const { orderId }: any = useParams();
  const dispatch = useAppDispatch();
  const history = useHistory();

  const { appKey } = useSelector(getDistributorSelector);
  const orderInfo = useSelector(getClosedOrderDetailSelector);
  const {
    billingAddress,
    shippingAddress,
    driver: driverInfo,
    items: itemsOrder,
    totals: totalSummary,
  } = useSelector(getClosedOrderDetailSelector);

  const { data: orderStatusList } = useSelector(getOrderStatusSelector);
  const nextStatusOfCancel = getAvailableStatusOrder(ConstOrderStatus.CANCEL, orderStatusList);
  const nextStatus = getStatusOrderByID(nextStatusOfCancel[0], orderStatusList);

  console.log(nextStatusOfCancel);

  const [expandRowKeys, setExpandRowKeys] = useState<any>([]);

  useEffect(() => {
    getOrderDetail();
  }, []);

  useEffect(() => {
    if (_.isEmpty(orderStatusList) && orderInfo.statusId == ConstOrderStatus.CANCEL) {
      dispatch(getOrderStatusList({ appKey: appKey }));
    }
  }, [orderInfo]);

  const getOrderDetail = () => {
    const params = {
      appKey: appKey,
      orderId: orderId,
    };
    dispatch(getClosedOrderDetail(params));
  };

  const reOpenCancelOrder = async () => {
    const values = {
      appKey: appKey,
      orderIds: [orderId],
      statusId: nextStatusOfCancel[0],
    };

    const resultSave = await dispatch(updateOrderEffect(values));

    if (updateOrderEffect.fulfilled.match(resultSave)) {
      if (!resultSave.payload.error) {
        notification.success({
          message: '',
          description: 'The order has been updated successfully',
          duration: 5,
          className: 'ant-notification-no-title ant-notification-success',
          icon: <CheckCircleFilled />,
        });
        if (nextStatus.closed) {
          getOrderDetail();
        } else {
          let pathOrderDetail = `${RouteConst.ORDER_OPEN_DETAIL}`.replace(':orderId', orderId);
          history.replace(pathOrderDetail);
        }
      } else {
        notification.error({
          message: '',
          description: resultSave?.payload?.error?.message || '',
          duration: 0,
        });
      }
    }
  };

  const columns: ColumnsType<any> = [
    {
      title: '',
      dataIndex: 'image',
      className: 'col-image',
      key: 'image',
      render: (text, record: any) => (
        <>
          {record.image && (
            <div className="imgWrap">
              <Image src={record.image} fallback={defaultImage} preview={false} />
            </div>
          )}
        </>
      ),
    },
    {
      title: 'Product',
      dataIndex: 'name',
      className: 'col-name',
      key: 'name',
      render: (text, record: any) => {
        return (
          <>
            {record.name}
            {record?.sku && (
              <>
                <br />
                <span style={{ color: '#999' }}>SKU: {record?.sku}</span>
              </>
            )}
          </>
        );
      },
    },
    {
      title: 'Base Price (Excl. Tax)',
      dataIndex: 'basePrice',
      className: 'col-basePrice',
      key: 'basePrice',
      render: (text, record: any) => {
        return convertCurrency(record.price, orderInfo.currencyCode);
      },
    },
    {
      title: 'Qty',
      dataIndex: 'qty',
      className: 'col-qty',
      key: 'qty',
      render: (text, record: any, index) => <>{record.qtyOrdered}</>,
    },
    {
      title: 'Total (Excl. Tax)',
      dataIndex: 'totalPrice',
      className: 'col-totalPrice',
      key: 'totalPrice',
      render: (text, record: any) => {
        return convertCurrency(record.rowTotal, orderInfo.currencyCode);
      },
    },
  ];

  const addRowKey = key => {
    setExpandRowKeys([...expandRowKeys, key]);
  };

  const removeRowKey = key => {
    const rowKeys = expandRowKeys.filter(item => item != key);
    setExpandRowKeys(rowKeys);
  };

  const onChangeExpand = key => {
    if (!expandRowKeys.includes(key)) {
      addRowKey(key);
    } else {
      removeRowKey(key);
    }
  };

  return (
    <>
      <ScrollToTop />
      <div className="openOrderDetail">
        <animated.div style={fadeInUp1}>
          <Row>
            <Col lg={12} span={24}>
              <div className="backToList">
                <Button type="link" className="btnBackToList" onClick={history.goBack}>
                  <LeftOutlined /> Back
                </Button>
              </div>
            </Col>
            <Col lg={12} span={24}>
              <div className="btnGroupPrint">
                {orderInfo.statusId == ConstOrderStatus.CANCEL && (
                  <Popconfirm
                    title="Are you sure to reopen this order?"
                    onConfirm={reOpenCancelOrder}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button type="link" className="btnPrint">
                      <img src={icnReOpen} /> Reopen Order
                    </Button>
                  </Popconfirm>
                )}
              </div>
            </Col>
          </Row>

          <div className="orderInfoBox">
            <Row gutter={24}>
              <Col lg={12} span={24}>
                <div className="infoBox orderInfo">
                  <h3 className="title">Order Info</h3>
                  <div className="boxInfo">
                    <div className="item">
                      <div className="itemLbl">ID:</div>
                      <div className="itemValue">{orderInfo.id}</div>
                    </div>
                    <div className="item">
                      <div className="itemLbl">Create Date:</div>
                      <div className="itemValue">{moment(orderInfo.createdAt).format('DD/MM/YYYY HH:mm')}</div>
                    </div>
                    <div className="item">
                      <div className="itemLbl">Payment method:</div>
                      <div className="itemValue">{orderInfo?.paymentTitle}</div>
                    </div>
                    <div className="item">
                      <div className="itemLbl">Order Status:</div>
                      <div className="itemValue">{orderInfo?.status}</div>
                    </div>
                    <div className="item">
                      <div className="itemLbl">Payment Status:</div>
                      <div className="itemValue">
                        {orderInfo.paymentStatusId == 0 ? 'Unknown' : orderInfo?.paymentStatus || ''}
                        {/*{orderInfo?.paymentStatus}*/}
                      </div>
                    </div>
                    <div className="item">
                      <div className="itemLbl">
                        {orderInfo.shippingTitle === 'Collect'
                          ? 'Requested Collection Date'
                          : 'Requested Delivery Date'}
                        :
                      </div>
                      <div className="itemValue">
                        {orderInfo?.requestedDeliveryDate &&
                          moment(new Date(orderInfo?.requestedDeliveryDate)).format('DD/MM/YYYY')}
                      </div>
                    </div>
                    <div className="item">
                      <div className="itemLbl">Order Reference:</div>
                      <div className="itemValue">{orderInfo.bwxOrderReference}</div>
                    </div>
                  </div>
                </div>
              </Col>
              {driverInfo?.driverId != 0 && (
                <Col lg={12} span={24}>
                  <div className="infoBox driverInfo">
                    <h3 className="title">Driver Info</h3>
                    <div className="boxInfo">
                      <DriverInfo driverInfo={driverInfo} />
                    </div>
                  </div>
                </Col>
              )}
            </Row>
          </div>
        </animated.div>

        <animated.div style={fadeInUp2}>
          <div className="infoBox orderNote">
            <h3 className="title">Order Note</h3>
            {orderInfo?.orderNoteHistory?.length > 0 && (
              <div className="boxInfo">
                <h4 className="subTitle">Messages</h4>
                <div className="message-wrap">
                  {orderInfo.orderNoteHistory.map((mes: any, index: number) => (
                    <div className="message" key={index}>
                      <div className="message-sender">
                        <div className="message-sender-name">
                          {mes.firstName} {mes.lastName}
                        </div>
                        <div className="message-sender-time">{mes.dateUp}</div>
                      </div>
                      <div className="message-content">{mes.message}</div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </animated.div>

        <animated.div style={fadeInUp2}>
          <div className="infoBox customerInfo">
            <h3 className="title">Customer Details</h3>
            <div className="boxInfo">
              <div className="item customerName">
                <span className="itemLbl">Customer Name:</span>
                <span className="itemValue">{orderInfo?.shopName || ''}</span>
              </div>
              <Row gutter={24}>
                <Col lg={12} span={24}>
                  <div className="subInfoBox invoiceInfo">
                    <h4 className="title">Invoice Address</h4>
                    <div className="subInfoInfo">
                      <Address address={billingAddress} />
                    </div>
                  </div>
                </Col>
                <Col lg={12} span={24}>
                  <div className="subInfoBox shippingInfo">
                    <h4 className="title">Shipping Address</h4>
                    <div className="subInfoInfo">
                      <Address address={shippingAddress} />
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </animated.div>

        <animated.div style={fadeInUp2}>
          <div className="orderSummary">
            <h3 className="title">Order Summary</h3>

            <div className="boxInfo">
              <div className="tableItemOrder-wrap">
                <Table
                  className="hasResponsive tableItemOrder isClosedOrder"
                  rowKey={record => record.orderItemId}
                  columns={columns}
                  dataSource={itemsOrder}
                  pagination={false}
                  locale={{
                    emptyText: 'There are no products in this order',
                  }}
                  expandable={{
                    expandRowByClick: false,
                    expandedRowRender: record => (
                      <ProductSerial
                        orderId={orderInfo.siteOrderId}
                        itemId={record.orderItemId}
                        qty={record.qtyOrdered}
                        serialNumbers={record?.serialNumbers}
                        isClosed
                        onCloseExpand={() => removeRowKey(record.orderItemId)}
                      />
                    ),
                    rowExpandable: record => record.requiredSerialNumber,
                  }}
                  expandedRowKeys={expandRowKeys}
                  onExpand={(expanded, record) => {
                    onChangeExpand(record.orderItemId);
                  }}
                  // onChange={onTableChange}
                  // pagination={{
                  //     ...orderPagination,
                  //     ...screenPagination,
                  // }}
                />
              </div>
              <Row>
                <Col lg={12} span={24} />
                <Col lg={12} span={24}>
                  <div className="totalOrderBox">
                    <div className="totalOrderBox-inner">
                      {_.map(totalSummary, (item, index) => {
                        let labelShow = '';
                        if (item.code == 'subtotal') {
                          labelShow = 'Products:';
                        } else if (item.code == 'discount') {
                          labelShow = 'Discount:';
                        } else if (item.code == 'shipping') {
                          labelShow = 'Shipping:';
                        } else if (item.code == 'grand_total') {
                          labelShow = 'Total (excl. Tax):';
                        } else if (item.code == 'tax') {
                          labelShow = 'Taxes:';
                        } else if (item.code == 'grand_total_incl') {
                          labelShow = 'Total (incl. Tax):';
                        }
                        let priceShow = convertCurrency(item.price, orderInfo.currencyCode);
                        // if (item.price === 0){return}
                        return (
                          <React.Fragment key={index}>
                            <div className={`lineItem ${item.code}`}>
                              <div className="itemLbl">{labelShow || item.code}</div>
                              <div className="itemValue">{priceShow}</div>
                            </div>
                          </React.Fragment>
                        );
                      })}
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </animated.div>
      </div>
    </>
  );
};

export default ClosedOrderDetail;
