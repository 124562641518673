import React, {useEffect, useState} from "react";
import {Image, Collapse, Form, Typography, Row, Col, Tag, Input, Table, Modal, Button, notification, DatePicker} from "antd";
import {DesktopOutlined,
    UserOutlined,
    CaretDownFilled,
    EyeTwoTone,
    PrinterOutlined,
    CheckCircleFilled} from "@ant-design/icons";
import {useSpring, animated} from "react-spring";
import {Link, useHistory} from "react-router-dom";
import moment, { Moment } from 'moment';
import { format, fromUnixTime } from 'date-fns';


import "./style.scss";
import {useSelector} from "react-redux";
import {ColumnsType} from "antd/es/table";
import Space from "antd/lib/space";
import {Tooltip} from "antd";
import {TablePaginationConfig} from "antd/lib/table";
import {RootState, useAppDispatch} from "../../../core/app.store";
import {getOpenOrderSelector,
    getOrderPaymentSelector,
    getOrderStatusSelector} from "../../../core/order/selectors";
import {
    checkHasNewOrder, getOpenOrderList,
    getOrderPaymentList,
    getOrderStatusList,
    updateOrderEffect
} from "../../../core/order/effects";
import {convertCurrency, getAvailableStatusOrder, getDateInTimeStamp, getSafeValue} from "../../../utils";
import SelectOption from "../../../components/SelectOption";
import {getDistributorSelector} from "../../../core/admin/selectors";
// import {isDesktop} from "react-device-detect";
import SelectConfirm from "../../../components/SelectConfirm";
import _ from "lodash";
import * as RouteConst from "../../../constants/RouteConst";
import {baseUrl} from "../../../services/urlAPI";
import imgView from '../../../assets/images/view.svg';
import PrintButton from "../../../components/PrintButton";
import {useLocation} from "react-router";
import qs from 'query-string';
import ScrollToTop from "../../../components/ScrollToTop";
import get from "lodash/get";

const {Title} = Typography;
const { Panel } = Collapse;

const timeIntevalCheckOrder = 2 * 60 * 1000; // 2' in milisecond


const OrderOpen = () => {

  const [form] = Form.useForm();
  const [formUpdateOrders] = Form.useForm();
  const dispatch = useAppDispatch();
    const history = useHistory();
    const location = useLocation();

    const locationSearch = location.search;
    const params = qs.parse(locationSearch, { arrayFormat: 'comma',parseNumbers: true });

    const [screenPagination, setScreenPagination] = useState({
        current: params.pageNumber ? params.pageNumber : 1,
        pageNumber: 1,
        pageSize: localStorage.getItem('pageSizeOpen') || 10,
        position: ['topRight', 'bottomRight'],
        pageSizeOptions: ['10', '20', '50'],
        showSizeChanger: true,
        showTotal: (total, range) => `${range[0]}-${range[1]} of ${total}`,
    });
    const [selectedOrder, setSelectedOrder] = useState<any>([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [disableBulkUpdate, setDisableBulkUpdate] = useState(true);
    const [availableSttBulkUpdate, setAvailableSttBulkUpdate] = useState([]);
    const showFilter = () => {
        const paramsSearch = _.omit(params, ['fromDate', 'toDate',]);
        if (!_.isEmpty(paramsSearch) || params.fromDate || params.toDate){
            return true;
        } else {
            return false;
        }
    };

    const {data: openOrder, isLoading, pagination: orderPagination} = useSelector(getOpenOrderSelector);
    const {data: orderStatusList, isLoading: loadingGetOrderStatus} = useSelector(getOrderStatusSelector);
    const orderStatusListFilter = orderStatusList.filter(item => !item.closed);

    const hasNewOrder = useSelector((state: RootState) => get(state.order.checkNewOrder, 'hasNewOpenOrder', false));

    const {data: orderPaymentStatus} = useSelector(getOrderPaymentSelector);
    const {appKey} = useSelector(getDistributorSelector);

  const fadeInUp = {
    delay: 100,
    from: {
      opacity: 0,
      translateY: 80
    },
    to: {
      opacity: 1,
      translateY: 0
    }
  };
  const fadeInUp1 = useSpring(fadeInUp);
  const fadeInUp2 = useSpring({...fadeInUp, delay: 300});


    useEffect(() => {
        const interval = setInterval(() => {
            checkNewOrder();
        }, timeIntevalCheckOrder);
        return () => clearInterval(interval);
    }, []);


    useEffect(() => {
        if (_.isEmpty(orderStatusList)) {
            dispatch(getOrderStatusList({appKey: appKey}));
        }
        if (_.isEmpty(orderPaymentStatus)) {
            dispatch(getOrderPaymentList({appKey: appKey}));
        }

      //@ts-ignored
        const fromDate = params.fromDate ? moment(params.fromDate) : undefined;
      //@ts-ignored
        const toDate =  params.toDate ? moment(params.toDate) : undefined;

        form.setFieldsValue({
            ...params,
            fromDate: fromDate,
            toDate: toDate
        });

        getOrder(params);

    }, []);


    const getOrderAndCloseNofi = () => {
        getOrder();
        history.push('/');
        notification.close('newOrderNofi');
    };

    const contentNofiNewOrder = (
        <div>You have new orders.
            <Button type='link'
              className="btnReloadGetOrder"
              onClick={()=>getOrderAndCloseNofi()}>Reload</Button>
        </div>
    );

    useEffect(() => {
        if (hasNewOrder){
            notification.info({
                message: '',
                description: contentNofiNewOrder,
                duration: 0,
                className: 'newOrderNofi',
                key: 'newOrderNofi'
            });
        }
    }, [hasNewOrder]);


    const checkNewOrder = () => {
        const values = {
            appKey: appKey,
            pageNumber: 1,
        };
        dispatch(checkHasNewOrder(values));
    };


    const getOrder = (params?: any) => {

        const fromDate: Moment = (form.getFieldValue('fromDate'))?.format('YYYY-MM-DD') || '';
        const toDate: Moment = (form.getFieldValue('toDate'))?.format('YYYY-MM-DD') || '';

        const values = {
            ...screenPagination,
            appKey: appKey,
            pageNumber: 1,
            ...form.getFieldsValue(),
            fromDate: fromDate,
            toDate: toDate,
            ...params,
        };
        dispatch(getOpenOrderList(values));
    };

    const onSubmit = values => {
        const fromDate: Moment = (values.fromDate)?.format('YYYY-MM-DD') || '';
        const toDate: Moment = (values.toDate)?.format('YYYY-MM-DD') || '';

        const params = {
            ...values,
            fromDate: fromDate,
            toDate: toDate,
        };

        const newLocation = addParamsLocation(params);
        history.replace({ pathname: location.pathname, search: newLocation });
        getOrder(params);
    };

    const addParamsLocation = (param?: any) => {
        const locationSearch = location.search;
        const params = qs.parse(locationSearch, { arrayFormat: 'comma',parseNumbers: true });
        const newParams = qs.stringify({...params, ...param}, { arrayFormat: 'comma' });
        return newParams;
    };

    const clearSearch = () => {
        form.resetFields();
        history.replace({ pathname: location.pathname});
        getOrder();
    };

    const onTableChange = (pagination: TablePaginationConfig, filters, sorter) => {
        const values = {
            ...pagination,
            ...filters,
            ...{ pageNumber: pagination.current },
            ...{ sortBy: sorter.column ? sorter.columnKey : '', sortDirection: sorter.order === 'ascend' ? 'asc' : 'desc' },
        };
        setScreenPagination({ ...screenPagination, ...values });

        const newLocation = addParamsLocation({pageNumber: values.current});
        history.replace({ pathname: location.pathname, search: newLocation });
        getOrder(values);
        localStorage.setItem('pageSizeOpen', values.pageSize);
    };

    const columnsData: ColumnsType<any> = [
        {
            title: 'Order Number',
            dataIndex: 'id',
            className: 'col-Id',
            key: 'id',
            sorter: true,
            fixed: 'left', //record.isNewOrder
            // width: 140,
            // defaultSortOrder: 'descend', // tạm thời comment vì API chưa có sort
            render: (text, record: any) => (
                <>{record.id} &nbsp;&nbsp;{record.isNewOrder && <Tag color="#1890ff">New</Tag>}</>
            ),
        },
        {
            title: 'Created Date',
            dataIndex: 'createdDate',
            className: 'col-createdDate',
            key: 'createdDate',
            sorter: true,
            defaultSortOrder: 'descend',
            render: (text, record: any) => (
                <>{record.createdAt ? moment(record.createdAt).format('DD/MM/YYYY HH:mm') : ''}</>
            ),
        },
        {
            title: 'Customer Name',
            dataIndex: 'shopName',
            className: 'col-shopName',
            key: 'shopName',
            sorter: true,
            render: (text, record: any) => (
                <div className="td-shopName">
                    {record.shopName || ''}
                </div>
            ),
            // sorter: true,
        },
        {
            title: 'Total Amount',
            dataIndex: 'grandTotal',
            className: 'col-totalAmount',
            key: 'grandTotal',
            sorter: true,
            render: (value, record) => {
                return (
                  <span className="lblPrice">
                    {convertCurrency(record.grandTotal, record.currencyCode)}
                  </span>
                )

            },
        },

        {
            title: 'Payment Status',
            dataIndex: 'paymentStatus',
            className: 'col-role',
            render: (value, record) => {
                return (
                    <>
                        {/*<SelectOption*/}
                            {/*data={orderPaymentStatus}*/}
                            {/*value={record?.paymentStatusId || ''}*/}
                            {/*style={{width: '100%'}}*/}
                            {/*onChange={(value)=>{updateOrder({paymentStatusId: value, orderIds: [record.siteOrderId]})}}*/}
                            {/*size="large" />*/}
                        <SelectConfirm
                            confirmMessage="Are you sure to change payment status?"
                            data={orderPaymentStatus}
                            value={record?.paymentStatusId || 0}
                            onConfirm={(value)=>{updateOrder({paymentStatusId: value, orderIds: [record.siteOrderId]})}}
                            style={{width: '100%'}}
                            size="large"
                        />
                    </>
                )
            },
        },
        {
            title: 'Order Status',
            dataIndex: 'status',
            className: 'col-status',
            width: '250px',
            render: (value, record, index) => {

                const availableStatus = getAvailableStatusOrder(record?.statusId, orderStatusList);
                return (
                    <>
                        <SelectConfirm
                            confirmMessage="Are you sure to change order status?"
                            data={orderStatusList}
                            value={record?.statusId || ''}
                            onConfirm={(value)=>{updateOrder({statusId: value, orderIds: [record.siteOrderId]})}}
                            // style={{width: '100%'}}
                            style={{width: '200px'}}
                            availableStatus={availableStatus}
                            size="large"
                        />
                    </>
                )
            },
        },
        {
            title: 'Action',
            dataIndex: 'action',
            className: 'col-action',
            fixed: 'right',
            width: 100,
            render: (text, record: any) => {
                return (
                    <div className="actionOrderList">
                        <Tooltip title="View Details">
                            {/*<Link to={RouteConst.USER_VIEW_URL.replace(':id', record.id)}>*/}
                            <Link to={RouteConst.ORDER_OPEN_DETAIL.replace(':orderId', record.siteOrderId)}
                                onClick={(e)=>e.stopPropagation()}
                            >
                                <img src={imgView} />
                            </Link>
                        </Tooltip>
                    </div>
                )
            },
        },
    ];

    const onSelectChange = (selectedRowKeys, selectedRows) => {
        setSelectedOrder(selectedRowKeys);

        const numberOrder = _.uniqBy(selectedRows, 'statusId');
        let isDisabled = numberOrder.length>1 ? true : false;

        setDisableBulkUpdate(isDisabled);
        setAvailableSttBulkUpdate(getAvailableStatus(numberOrder));
    };

    const rowSelection = {
        selectedRowKeys: selectedOrder,
        onChange: onSelectChange,
    };

    const getAvailableStatus = (order) => {
        if (order.length > 1 && !_.isEmpty(order)){
            return [];
        } else {
            const uniqOrder = getSafeValue(order, '[0]', {});
            const status = getAvailableStatusOrder(uniqOrder?.statusId, orderStatusList);
            return status;
        }
    };


    const updateOrder = async (params?: any) => {
        const values = {
            appKey: appKey,
            ...params,
        };

        const resultSave = await dispatch(updateOrderEffect(values));

        if (updateOrderEffect.fulfilled.match(resultSave)) {
            if (!resultSave.payload.error){
                notification.success({
                    message: '',
                    description: 'The order has been updated successfully',
                    duration: 5,
                    className: 'ant-notification-no-title ant-notification-success',
                    icon: <CheckCircleFilled />,
                });
                getOrder();
            } else {
                notification.error({
                    message: '',
                    description: resultSave?.payload?.error?.message || '',
                    duration: 0,
                });
            }
        }
    };


    const showModal = () => {
        setIsModalVisible(true);
    };
    const hideModal = () => {
        setIsModalVisible(false);
    };

    const updateMultiOrder = (data) => {
        const values = {
            orderIds: selectedOrder,
            ...data,
        };

        updateOrder(values);
        hideModal();
        setAvailableSttBulkUpdate([]);
        setSelectedOrder([]);
        formUpdateOrders.resetFields();
    };

    const onEditRow = record => {
        let pathOrderDetail = `${RouteConst.ORDER_OPEN_DETAIL}`.replace(':orderId', record.siteOrderId);
        history.push(pathOrderDetail);
    };

    return (
      <>
          <ScrollToTop />
      <div className="openOrderList">
          <animated.div style={fadeInUp1}>

              <Collapse
                  expandIconPosition="right"
                  defaultActiveKey={`${showFilter() ? ['1'] : ''}`} //BWORX-796: follow this ticket, default is collapsed
              >
                  <Panel header="Filters" key="1">
                      <div className="formSearch">
                          <Form
                              layout="vertical"
                              form={form}
                              name="searchForm"
                              onFinish={onSubmit}
                              initialValues={{}}
                          >
                              <Row gutter={24}>
                                  <Col xl={8} md={12} span={24}>
                                      <Form.Item
                                          name="orderId"
                                          label="Order Number">
                                          <Input size="large" />
                                      </Form.Item>
                                  </Col>

                                  <Col xl={8} md={12} span={24}>
                                      <Form.Item
                                          name="shopName"
                                          label="Customer Name">
                                          <Input size="large" />
                                      </Form.Item>
                                  </Col>

                                  <Col xl={8} md={12} span={24}>
                                      <div className="dateFromTo" >
                                          <Form.Item label="Date Range" name="fromDate" className="flexCol">
                                              <DatePicker
                                                  suffixIcon={<CaretDownFilled />}
                                                  size="large"
                                                  showToday={false}
                                                  clearIcon={false}
                                                  dropdownClassName="quote-picker-dropdown"
                                                  className="quote-picker-request"
                                                  inputReadOnly
                                                  placeholder=""
                                                  format="DD/MM/YYYY"
                                                  // @ts-ignore
                                                  disabledDate={current => {
                                                      return form.getFieldsValue().toDate
                                                          ? current > moment(form.getFieldsValue().toDate).endOf('day')
                                                          : current > moment(new Date()).endOf('day');
                                                  }}
                                              />
                                          </Form.Item>
                                          <div className="space-to-request">
                                              <div>To</div>
                                          </div>

                                          <Form.Item label=" " name="toDate" className="flexCol">
                                              <DatePicker
                                                  suffixIcon={<CaretDownFilled />}
                                                  size="large"
                                                  showToday={false}
                                                  clearIcon={false}
                                                  dropdownClassName="quote-picker-dropdown"
                                                  className="quote-picker-request"
                                                  inputReadOnly
                                                  placeholder=""
                                                  format="DD/MM/YYYY"
                                                  // @ts-ignore
                                                  disabledDate={current => {
                                                      return form.getFieldsValue().fromDate
                                                          ? (current < moment(form.getFieldsValue().fromDate).startOf('day') || current > moment(new Date()).endOf('day'))
                                                          : current > moment(new Date()).endOf('day');
                                                  }}
                                              />
                                          </Form.Item>
                                      </div>
                                  </Col>

                                  <Col xl={8} md={12} span={24}>
                                      <Form.Item
                                          name="orderStatus"
                                          label="Order Status">
                                          <SelectOption data={orderStatusListFilter}
                                                        mode="multiple"
                                                        showArrow
                                                        maxTagCount={'responsive'}
                                                        size="large" />
                                      </Form.Item>
                                  </Col>

                                  <Col xl={8} md={12} span={24}>
                                      <Form.Item
                                          name="paymentStatus"
                                          label="Payment Status">
                                          <SelectOption data={orderPaymentStatus}
                                                        mode="multiple"
                                                        showArrow
                                                        maxTagCount={'responsive'}
                                                        size="large" />
                                      </Form.Item>
                                  </Col>
                                  <Col xl={8} md={12} span={24}>
                                      <div className="btnGroupSearch">
                                          <Form.Item className="btnContainer">
                                              <Button type="default"
                                                      onClick={()=>clearSearch()}
                                                      size="large"
                                                      className="btnClearSearch">Clear All</Button>

                                              <Button type="primary" htmlType="submit" size="large" className="submitBtn">
                                                  Search
                                              </Button>
                                          </Form.Item>
                                      </div>

                                  </Col>
                              </Row>

                          </Form>
                      </div>
                  </Panel>
              </Collapse>


          </animated.div>


          <animated.div style={fadeInUp2}>
              <div className="wrapTable">

              <div className="wrapTableInner">
                  <div>
                      <p>Please select orders with same status to bulk update</p>
                  <Button type="primary"
                          htmlType="button"
                          size="large"
                          disabled={_.isEmpty(selectedOrder) || disableBulkUpdate}
                          onClick={showModal}
                          className="submitBtn"
                          style={{marginBottom: '13px'}}
                  >Bulk Update
                  </Button>
                  </div>
                  <Table className="hasResponsive tableOrderOpen"
                         rowKey={record => record.siteOrderId}
                         // size={isDesktop ? "large" : 'middle'}
                         columns={columnsData}
                         dataSource={openOrder}
                         rowClassName={(record, index) => record.isNewOrder ? 'isNewOrder' :  ''}
                         rowSelection={{
                             type: 'checkbox',
                             ...rowSelection,
                         }}
                         onChange={onTableChange}
                         scroll={{ x: 1000 }}
                         tableLayout="auto"
                         pagination={{
                             ...screenPagination,
                             ...orderPagination,
                         }}
                         onRow={record => {
                             return {
                                 onClick: () => {
                                     onEditRow(record);
                                 },
                             };
                         }}
                         locale={{
                             emptyText: 'No orders found'
                         }}

                  />
              </div>
              </div>
          </animated.div>
    </div>

      <Modal visible={isModalVisible}
             footer={null}
             closable={false}
             onCancel={hideModal}
      >
          <div className="updateOrdersForm">
              <Form
                  form={formUpdateOrders}
                  name="form-update-orders"
                  hideRequiredMark
                  labelCol={{ span: 8 }}
                  wrapperCol={{ span: 16 }}
                  labelAlign="left"
                  onFinish={updateMultiOrder}
              >
                  <h2 className="titleForm">Bulk Update</h2>
                  <Form.Item
                      label="Payment Status"
                      name="paymentStatusId"
                  >
                      <SelectOption
                          data={orderPaymentStatus}
                          placeholder="Select payment status"
                        />
                  </Form.Item>

                  <Form.Item
                      label="Order Status"
                      name="statusId"
                  >
                      <SelectOption
                          placeholder="Select order status"
                          availableStatus={availableSttBulkUpdate}
                          data={orderStatusList} />
                  </Form.Item>

                  {/*<Form.Item wrapperCol={{ span: 24 }} className="btnContainer">*/}
                      <div className="btnGroupFormUpdateOrder">
                          <Button type="default"
                                  onClick={hideModal}
                                  className="forgotBtn">Cancel</Button>

                          <Button type="primary"
                                  htmlType="submit"
                                  disabled={_.isEmpty(selectedOrder)}
                                  className="submitBtn">
                              Done
                          </Button>
                      </div>
                  {/*</Form.Item>*/}

              </Form>
          </div>
      </Modal>

    </>
  );
};

export default OrderOpen;
