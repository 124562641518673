import React, { useEffect } from 'react';

interface Props {
    driverInfo: any;
};

const DriverInfo = ({driverInfo}: Props) => {


    if (!driverInfo) {return null}
  return (
      <>
          <div className="item">
              <div className="itemLbl">ID:</div>
              <div className="itemValue">{driverInfo?.driverId}</div>
          </div>
          <div className="item">
              <div className="itemLbl">Name:</div>
              <div className="itemValue">{driverInfo?.driverName}</div>
          </div>
          <div className="item">
              <div className="itemLbl">Location:</div>
              <div className="itemValue">{driverInfo?.driverLocation}</div>
          </div>
          {/*<div className="item">*/}
              {/*<div className="itemLbl">Coordination:</div>*/}
              {/*<div className="itemValue">{driverInfo?.driverCoordinate}</div>*/}
          {/*</div>*/}
      </>
  );
};

export default DriverInfo;
