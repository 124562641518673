import React, { useEffect, useState } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import LoadingSpin from "../../components/LoadingSpin";
import { Container } from "react-bootstrap";
import {RootState} from '../../core/app.store';
import {Layout, Spin, Avatar, Popover, Row, Col, Modal, Button, notification} from "antd";

import {DesktopOutlined, UserOutlined, LoadingOutlined, CaretDownOutlined} from "@ant-design/icons";
import {useHistory, useLocation} from "react-router";
import {logout} from '../../core/admin/admin.slice';
import logo from "../../assets/images/logo.png";
import { get } from 'lodash';
import {getDistributorSelector} from "../../core/admin/selectors";
import {clearNewOrderNofi} from '../../core/order/order.slice';


const { Header, Sider, Content, Footer } = Layout;

const PrivateLayout = (props) => {

    const dispatch = useDispatch();
    const location = useLocation();

    const loadingGetListOpenOrder = useSelector((state: RootState) => get(state.order.openOrderList, 'isLoading', false));
    const loadingGetListClosedOrder = useSelector((state: RootState) => get(state.order.closedOrderList, 'isLoading', false));
    const loadingGetOpenOrderDetail = useSelector((state: RootState) => get(state.order.orderOpenDetail, 'isLoading', false));
    const loadingGetClosedOrderDetail = useSelector((state: RootState) => get(state.order.closedOrderDetail, 'isLoading', false));
    const admin = useSelector((state: RootState) => state.admin.loginUser);
    const [avatarPopoverVisible, setAvatarPopoverVisible] = useState(false);
    const {logoUrl} = useSelector(getDistributorSelector);

    const loading = loadingGetListOpenOrder || loadingGetListClosedOrder || loadingGetOpenOrderDetail || loadingGetClosedOrderDetail;

    const logOut = () => {
        dispatch(logout());
    };

    useEffect(() => {
        // clear data redux store when navigate
        dispatch(clearNewOrderNofi());
        notification.close('newOrderNofi');
    }, [location]);

    const confirmLogoutModal = () => {
        Modal.confirm({
            content: 'Do you want to log out?',
            okText: 'Yes',
            cancelText: 'No',
            width: 330,
            className: 'navigation-confirm',
            centered: true,
            maskClosable: true,
            onOk() {
                logOut();
            },
            onCancel() {},
        });
        return null;
    };

    const contentPopover = (
        <div style={{minWidth: '130px'}}>
            <a onClick={()=>confirmLogoutModal()}>Logout</a>
        </div>
    );

  return (
    <Layout className="privateRouteMain" style={{minHeight: '100vh'}}>
        <Header className="header">
            <Container>
                <Row>
                    <Col span={12}>
                        <div className="logo">
                        <img src={logoUrl||logo} height={64}/>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div id="userPopover" className="userPopover">
                            <Popover
                                content={contentPopover}
                                trigger="click"
                                visible={avatarPopoverVisible}
                                onVisibleChange={() => setAvatarPopoverVisible(!avatarPopoverVisible)}
                                placement="bottomRight"
                            >
                                <Button type="link" className="btnEmployeeName">
                                    {admin?.employee ? `${admin.employee.firstname} ${admin.employee.lastname}` : ''}
                                    <CaretDownOutlined />
                                </Button>
                            </Popover>
                        </div>


                    </Col>

                </Row>

            </Container>
        </Header>
        <div className="privateContainer">
            <Spin
                spinning={loading}
                size="large"
                indicator={<LoadingSpin />}
                wrapperClassName={`${loading ? 'page-loading' : ''}`}
            >
                <div className="">
                    <div className="privatePageMain">

                            <Container>
                                <Content>{props.children}</Content>
                            </Container>

                    </div>
                </div>
            </Spin>

        </div>
        <Footer>
            <Container>
            {/*<div>Craft with ❤️&nbsp; from JMango360</div>*/}
            </Container>
        </Footer>
    </Layout>
  );
};

export default PrivateLayout;
