import React, {useEffect, useState} from 'react';
import {Select, Popconfirm, SelectProps} from 'antd';
import _ from 'lodash';

interface Props extends SelectProps<any> {
    data: any,
    confirmMessage: string,
    onConfirm: any,
    onCancel?: any,
    availableStatus?: any,
};
const {Option} = Select;
const SelectConfirm = ({data,confirmMessage, onConfirm, onCancel, availableStatus, ...rest}: Props) => {
    // useEffect(() => {
        // console.log('data-option', data);
        // console.log('value-option', value);
    // }, [data]);

    const [show, setShow] = useState(false);
    const [valueChange, setValueChange] = useState<any>();

    const confirmChange = () => {
        onConfirm(valueChange);
        setShow(false);
    };

    const cancelChange = () => {
        if (_.isFunction(onCancel)){
            onCancel();
        }
        setShow(false);
    };

    const onChangeSelect = (value) => {
        setValueChange(value);
        setShow(true);
    };

    const onVisibleChange = () => {
        if (show){
            cancelChange();
        }
    };

    const preventClick = (e) => {
        e.stopPropagation();
    };

    return (
        <>
        <div onClick={preventClick}>
            <Popconfirm
                placement="topLeft"
                title={confirmMessage}
                onConfirm={confirmChange}
                onCancel={cancelChange}
                okText="Yes"
                cancelText="No"
                visible={show}
                onVisibleChange={onVisibleChange}
                overlayClassName="confirmSelect"
            >
                <Select {...rest}
                    onChange={onChangeSelect}>
                    {data.map(d => {
                        let isDisabled = false;
                        if (availableStatus){
                            if (_.includes(availableStatus, d.id)){
                                isDisabled = false;
                            } else {
                                isDisabled = true;
                            }
                        }
                        return (
                            <Option key={d.id} value={d.id} disabled={isDisabled} hidden={d.id==0}>
                                {d.name}
                            </Option>
                        )
                    })}
                </Select>
            </Popconfirm>
        </div>
        </>
    );
};

export default SelectConfirm;
