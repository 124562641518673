import { createAsyncThunk } from '@reduxjs/toolkit';
import {apiInstance} from "../../../services/api";
import ApiConstants from "../../../constants/endpoint";
import {loginAppKey} from "../../../constants";

// services
export const loginAPI = async ({ username, password }) => {
  const url = ApiConstants.ADMIN_LOGIN_URL;
  const result = await apiInstance.post(url, {
      email: username,
      password: password,
      appKey: loginAppKey
  });
  return result;
};

