import { createSelector } from '@reduxjs/toolkit';
import {RootState} from '../../app.store';
import {getSafeValue} from "../../../utils";
import {searchProductToAddOrder} from "../effects";

const orderSelector = (state: RootState) => state.order;
export const getOpenOrderSelector = createSelector(orderSelector, state => state.openOrderList || {});
export const getClosedOrderSelector = createSelector(orderSelector, state => state.closedOrderList || {});
export const getOrderStatusSelector = createSelector(orderSelector, state => state.orderStatusList || {});
export const getOrderPaymentSelector = createSelector(orderSelector, state => state.orderPaymentList || {});

export const getOrderDetailSelector = createSelector(orderSelector, state => {
    return getSafeValue(state.orderOpenDetail, 'data', {});
});
export const getClosedOrderDetailSelector = createSelector(orderSelector, state => {
    return getSafeValue(state.closedOrderDetail, 'data', {});
});

export const getProductSearchToAddSelector = createSelector(orderSelector, state => state.productSearchToAdd || {});