/* eslint-disable no-param-reassign */
import React from 'react';
import axios from 'axios';
import util from 'util';
import { baseUrl } from './urlAPI';
import {HeaderKey} from '../constants';
// import { logout, clearExpireSession } from '../core/admin/admin.slice';

export const apiInstance = axios.create({
  headers: {
    'Content-Type': 'application/json',
  },
  baseURL: baseUrl,
});

export const setApiHeader = () => {
  const kpData = localStorage.getItem('kpData');
  if (kpData) {
    const kpDataParse = JSON.parse(kpData);
    apiInstance.defaults.headers[HeaderKey.JM360_KP] = kpDataParse.keypairData;
    apiInstance.defaults.headers[HeaderKey.JM360_KP_EXP] = kpDataParse.keypairExpiration;
    apiInstance.defaults.headers[HeaderKey.JM360_KP_VER] = kpDataParse.keypairVersion;
  }

};
setApiHeader();

apiInstance.interceptors.response.use(
  response => {
    // console.log('get new token using refresh token', getLocalRefreshToken());
    return response;
  },

  async error => {
    throw error;
  }
);

const handleRequest = async (_request, thunkApi?) =>
  _request
    .then(response => response.data)
    .catch(error => {
      if (thunkApi) {
        return thunkApi.rejectWithValue(error?.response?.data || error);
      }
      return Promise.reject(error);
    });

export class api {
  static post =
    (url, axiosConfig = {}) =>
    (params = {}, thunkApi?) => {
      handleRequest(apiInstance.post(url, params, axiosConfig), thunkApi);
    };

  static put =
    (url, axiosConfig = {}) =>
    (params = {}, thunkApi) =>
      handleRequest(apiInstance.put(url, params, axiosConfig), thunkApi);

  static get =
    (url, axiosConfig = {}) =>
    thunkApi =>
      handleRequest(apiInstance.get(url, axiosConfig), thunkApi);

  static delete =
    (url, axiosConfig = {}) =>
    (params = {}, thunkApi) =>
      handleRequest(apiInstance.delete(util.format(url, params), axiosConfig), thunkApi);
}

export const changeHeaderAPI = (headerObject: { [key: string]: any }) => {
  Object.assign(apiInstance.defaults, { headers: headerObject });
};

// export const removeHeaderAuthorization = () => {
//     delete apiInstance.defaults.headers[HeaderKey.JM360_AUTHORIZATION];
// };
