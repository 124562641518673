import React, { useEffect, useState } from 'react';
import './style.scss';
import { Select, Image } from 'antd';
import { CaretDownOutlined, CaretDownFilled, LoadingOutlined } from '@ant-design/icons';
import _ from 'lodash';
import { SelectProps } from 'antd/lib/select';
import {convertCurrency} from "../../utils";
import defaultImage from '../../assets/images/defaultImage.png';

const { Option } = Select;

interface Props extends SelectProps<any> {
  data: any;
  isFull?: boolean;
  allowSearch?: boolean;
  onLoadmore?: any;
  onSearch?: any;
}

function SelectProduct({ data, isFull, allowSearch, onSearch, onLoadmore, ...rest }: Props) {
  const [keySearch, setKeySearch] = useState('');

  const searchOnSelect = _.debounce(val => {
    onSearch({ query: val });
    setKeySearch(val);
  }, 700);

  const onScroll = e => {
    const popup = e.target;
    let positionLoad = popup.scrollHeight - (4 / 5) * popup.scrollHeight;
    if (popup.scrollTop >= positionLoad) {
      if (!rest.loading && !isFull && _.isFunction(onLoadmore)) {
        onLoadmore({ query: keySearch });
      }
    }
  };

  return (
    <div id="idSelectProduct" className="selectProduct">
      <Select
        {...rest}
        showSearch={true}
        loading={rest.loading}
        placeholder="Search by name or SKU"
        optionLabelProp="title"
        onPopupScroll={onScroll}
        filterOption={false}
        optionFilterProp="label"
        autoClearSearchValue={false}
        //@ts-ignore
        onSearch={searchOnSelect}
        notFoundContent="No product found"
        getPopupContainer={() => document.getElementById('idSelectProduct') as HTMLElement}
      >
        {data.map((item: any, index) => (
          <Option value={item.id} key={index} title={item.name}>
              <div className="optionRow">
                  <div className="img">
                      <Image src={item.image} fallback={defaultImage} preview={false} />
                  </div>
                  <div className="name">
                      {item.name}
                      {item?.sku && <><br /><span className="sku">SKU: {item?.sku}</span></>}
                      </div>
                  <div className="price">{convertCurrency(item.price, item.currencyCode)}</div>
              </div>
          </Option>
        ))}
      </Select>
    </div>
  );
}

export default SelectProduct;
