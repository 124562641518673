import { createSelector } from '@reduxjs/toolkit';
import {RootState} from '../../app.store';
import {getSafeValue} from "../../../utils/index";

const AppAdmin = (state: RootState) => state.admin;
export const getLoginUserSelector = createSelector(AppAdmin, state => state.loginUser || {});
export const getDistributorSelector = createSelector(AppAdmin, state => {
    let distributor = getSafeValue(state.loginUser, 'distributors.[0]', {});
    return distributor;
});
