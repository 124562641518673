import React, { useEffect, useState, useRef } from 'react';
import {
  Image,
  Popconfirm,
  InputNumber,
  Form,
  Typography,
  Row,
  Col,
  Table,
  Modal,
  Button,
  notification,
  Select,
  DatePicker,
  Input,
} from 'antd';
import {
  DesktopOutlined,
  UserOutlined,
  CaretDownFilled,
  EyeTwoTone,
  BarcodeOutlined,
  CheckCircleFilled,
  PrinterOutlined,
  LeftOutlined,
  DownOutlined,
  EditOutlined,
} from '@ant-design/icons';
import { useSpring, animated } from 'react-spring';
import { Link, useHistory } from 'react-router-dom';
import moment, { Moment } from 'moment';
import { format, fromUnixTime } from 'date-fns';
import './style.scss';
import { useSelector } from 'react-redux';
import { ColumnsType } from 'antd/es/table';
import Space from 'antd/lib/space';
import { Tooltip } from 'antd';
import { TablePaginationConfig } from 'antd/lib/table';
import _ from 'lodash';
import { RootState, useAppDispatch } from '../../../../core/app.store';
import { getDistributorSelector } from '../../../../core/admin/selectors';
import { useParams } from 'react-router-dom';
import {
  addProductToOrderEffect,
  deleteItemsOrderDetails,
  getOrderOpenDetail,
  getOrderPaymentList,
  getOrderStatusList,
  searchProductToAddOrder,
  updateOrderEffect,
  updateQtyOrderDetail,
  updateDeliveryDateEffect,
  updateOrderReferenceEffect,
} from '../../../../core/order/effects';
import {
  getOrderDetailSelector,
  getOrderPaymentSelector,
  getOrderStatusSelector,
  getProductSearchToAddSelector,
} from '../../../../core/order/selectors';
import { convertCurrency, getAvailableStatusOrder, getStatusOrderByID } from '../../../../utils';
import SelectConfirm from '../../../../components/SelectConfirm';
import DeleteConfirmPopover from '../../../../components/DeleteConfirmPopover';
import * as RouteConst from '../../../../constants/RouteConst';
import SelectProduct from '../../../../components/SelectProduct';
import get from 'lodash/get';
import { baseUrl } from '../../../../services/urlAPI';
import Address from '../../../../components/Address';
import DriverInfo from '../../../../components/DriverInfo';
import defaultImage from '../../../../assets/images/defaultImage.png';
import ProductSerial from '../../../../components/ProductSerial';
import ScrollToTop from '../../../../components/ScrollToTop';
import useOnClickOutside from '../../../../hooks/useClickOUtside';

const { Title } = Typography;

const pagination = {
  pageSize: 20,
  pageNumber: 1,
};

interface PropsRowQty {
  row: any;
  step?: number;
  index: string | number;
  onChangeQty?: any;
  value: any;
  disabled?: boolean;
}
const numberRegex = /\d*/;
const QtyInput = ({ row, index, onChangeQty, value, disabled }: PropsRowQty) => {
  const [rowValue, setRowValue] = useState(value);

  const onBlurQuantity = (event: React.FocusEvent<HTMLInputElement>) => {
    const { value: valueInput } = event.target;

    if (parseInt(valueInput) == 0 || !valueInput.match(numberRegex) || !valueInput) {
      onChangeQty(1);
      setRowValue(1);
    } else {
      onChangeQty(valueInput);
      setRowValue(valueInput);
    }
  };

  return (
    <>
      <div className="qtyBox">
        <div className="inputQty">
          {/* <span onClick={minusQuantity}><IoIosRemove/></span> */}
          <InputNumber
            className="input-qty"
            min={1}
            name={`qtyOrdered[${index}]`}
            type="number"
            pattern="\d*"
            onFocus={e => {
              e.target.select();
            }}
            onMouseUp={e => e.preventDefault()}
            onBlur={onBlurQuantity}
            onChange={value => {
              setRowValue(value);
            }}
            disabled={disabled}
            value={rowValue}
          />
        </div>
      </div>
    </>
  );
};

const DetailOpenOrder = () => {
  const fadeInUp = {
    delay: 100,
    from: {
      opacity: 0,
      translateY: 80,
    },
    to: {
      opacity: 1,
      translateY: 0,
    },
  };
  const fadeInUp1 = useSpring(fadeInUp);
  const fadeInUp2 = useSpring({ ...fadeInUp, delay: 300 });

  const [form] = Form.useForm();
  const { orderId }: any = useParams();
  const dispatch = useAppDispatch();
  const history = useHistory();

  const { appKey } = useSelector(getDistributorSelector);
  const { data: orderStatusList } = useSelector(getOrderStatusSelector);
  const { data: orderPaymentStatus } = useSelector(getOrderPaymentSelector);
  const loadingAddProduct = useSelector((state: RootState) => get(state.order.addProductToOrder, 'isLoading', false));
  const loadingUpdateQty = useSelector((state: RootState) => get(state.order.updateQtyOrder, 'isLoading', false));
  const {
    data: productList,
    isLoading: loadingGetProduct,
    isFull,
    pagination: paginationGetProduct,
  } = useSelector(getProductSearchToAddSelector);

  const orderInfo = useSelector(getOrderDetailSelector);
  const {
    billingAddress,
    shippingAddress,
    driver: driverInfo,
    items: itemsOrder,
    totals: totalSummary,
  } = useSelector(getOrderDetailSelector);

  const [isEdit, setIsEdit] = useState(false);
  const [selectedItem, setSelectedItem] = useState<any>([]);
  const [itemOrderList, setItemOrderList] = useState<any>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [expandRowKeys, setExpandRowKeys] = useState<any>([]);
  const [orderReferenceEdit, setOrderReferenceEdit] = useState<any>('');
  const [isConfirmDeliveryDate, setIsConfirmDeliveryDate] = useState<any>('');
  const [isConfirmChangeReference, setIsConfirmChangeReference] = useState<any>(false);
  const orderReferenceref = useRef<any>();
  useOnClickOutside(orderReferenceref, () => {
    if (
      (orderInfo.bwxOrderReference === null && !orderReferenceEdit) ||
      orderInfo.bwxOrderReference !== orderReferenceEdit
    ) {
      setIsConfirmChangeReference(true);
    }
  });

  useEffect(() => {
    if (_.isEmpty(orderStatusList)) {
      dispatch(getOrderStatusList({ appKey: appKey }));
    }
    if (_.isEmpty(orderPaymentStatus)) {
      dispatch(getOrderPaymentList({ appKey: appKey }));
    }
    getOrderDetail();
  }, []);

  useEffect(() => {
    if (!_.isEmpty(itemsOrder)) {
      setItemOrderList(itemsOrder);
    } else {
      setItemOrderList([]);
    }
  }, [itemsOrder]);

  useEffect(() => {
    setOrderReferenceEdit(orderInfo.bwxOrderReference || '');
  }, [orderInfo.bwxOrderReference]);

  const getOrderDetail = () => {
    const params = {
      appKey: appKey,
      orderId: orderId,
    };
    dispatch(getOrderOpenDetail(params));
  };

  const updateOrderStatus = async (params?: any) => {
    const values = {
      appKey: appKey,
      orderIds: [orderId],
      ...params,
    };

    const resultSave = await dispatch(updateOrderEffect(values));

    if (updateOrderEffect.fulfilled.match(resultSave)) {
      if (!resultSave.payload.error) {
        notification.success({
          message: '',
          description: 'The order has been updated successfully',
          duration: 5,
          className: 'ant-notification-no-title ant-notification-success',
          icon: <CheckCircleFilled />,
        });
        if (params.statusId) {
          const nextStatus = getStatusOrderByID(params.statusId, orderStatusList);
          if (nextStatus.closed) {
            let pathOrderDetail = `${RouteConst.CLOSED_ORDER_DETAIL}`.replace(':orderId', orderId);
            history.replace(pathOrderDetail);
          } else {
            getOrderDetail();
          }
        } else {
          getOrderDetail();
        }
      } else {
        notification.error({
          message: '',
          description: resultSave?.payload?.error?.message || '',
          duration: 0,
        });
      }
    }
  };

  const updateDeliveryDate = async (params?: any) => {
    const values = {
      appKey: appKey,
      orderIds: [orderId],
      ...params,
    };

    const resultSave = await dispatch(updateDeliveryDateEffect(values));

    if (updateDeliveryDateEffect.fulfilled.match(resultSave)) {
      if (!resultSave.payload.error) {
        notification.success({
          message: '',
          description: 'The order has been updated successfully',
          duration: 5,
          className: 'ant-notification-no-title ant-notification-success',
          icon: <CheckCircleFilled />,
        });
        if (params.statusId) {
          const nextStatus = getStatusOrderByID(params.statusId, orderStatusList);
          if (nextStatus.closed) {
            let pathOrderDetail = `${RouteConst.CLOSED_ORDER_DETAIL}`.replace(':orderId', orderId);
            history.replace(pathOrderDetail);
          } else {
            getOrderDetail();
          }
        } else {
          getOrderDetail();
        }
      } else {
        notification.error({
          message: '',
          description: resultSave?.payload?.error?.message || '',
          duration: 0,
        });
      }
    }
  };

  const updateOrderReference = async (params?: any) => {
    const values = {
      appKey: appKey,
      orderIds: [orderId],
      ...params,
    };

    const resultSave = await dispatch(updateOrderReferenceEffect(values));

    if (updateOrderReferenceEffect.fulfilled.match(resultSave)) {
      if (!resultSave.payload.error) {
        notification.success({
          message: '',
          description: 'The order has been updated successfully',
          duration: 5,
          className: 'ant-notification-no-title ant-notification-success',
          icon: <CheckCircleFilled />,
        });
        if (params.statusId) {
          const nextStatus = getStatusOrderByID(params.statusId, orderStatusList);
          if (nextStatus.closed) {
            let pathOrderDetail = `${RouteConst.CLOSED_ORDER_DETAIL}`.replace(':orderId', orderId);
            history.replace(pathOrderDetail);
          } else {
            getOrderDetail();
          }
        } else {
          getOrderDetail();
        }
      } else {
        notification.error({
          message: '',
          description: resultSave?.payload?.error?.message || '',
          duration: 0,
        });
      }
    }
  };

  const onUpdateQty = async () => {
    const editItems = _.map(itemOrderList, (item, index) => {
      return {
        orderItemId: item.orderItemId,
        qty: item.qtyOrdered,
      };
    });

    const values = {
      orderId: orderInfo.siteOrderId,
      appKey: appKey,
      items: editItems,
    };

    const resultUpdate = await dispatch(updateQtyOrderDetail(values));

    if (updateQtyOrderDetail.fulfilled.match(resultUpdate)) {
      if (!resultUpdate.payload.error && !_.isEmpty(resultUpdate.payload.order)) {
        notification.success({
          message: '',
          description: 'The order has been updated successfully',
          duration: 5,
          className: 'ant-notification-no-title ant-notification-success',
          icon: <CheckCircleFilled />,
        });
        getOrderDetail();
        setIsEdit(false);
      } else {
        notification.error({
          message: '',
          description: resultUpdate?.payload?.error?.message || 'An error has occurred. Please try again.',
          duration: 0,
        });
      }
    }
  };

  const onChangeQty = (value, index) => {
    let editItem = itemOrderList.map((item, itemIndex) => (index == itemIndex ? { ...item, qtyOrdered: value } : item));
    setItemOrderList(editItem);
  };

  const deleteItem = async items => {
    const values = {
      orderId: orderInfo.siteOrderId,
      appKey: appKey,
      orderItemId: items,
    };

    let messageDelete =
      items.length > 1 ? 'The selected items are deleted successfully' : 'The item is deleted successfully';

    const resultDelete = await dispatch(deleteItemsOrderDetails(values));

    if (deleteItemsOrderDetails.fulfilled.match(resultDelete)) {
      if (!resultDelete.payload.error) {
        notification.success({
          message: '',
          description: messageDelete,
          duration: 5,
          className: 'ant-notification-no-title ant-notification-success',
          icon: <CheckCircleFilled />,
        });
        // getOrderDetail();
        setSelectedItem([]);
      } else {
        notification.error({
          message: '',
          description: resultDelete?.payload?.error?.message || 'An error has occurred. Please try again.',
          duration: 0,
        });
      }
    }
  };

  const onCancelUpdateQty = () => {
    setItemOrderList(itemsOrder);
    setIsEdit(false);
  };

  const onSelectChange = selectedRowKeys => {
    setSelectedItem(selectedRowKeys);
  };

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      onSelectChange(selectedRowKeys);
      // console.log(`selectedRowKeys: ${[selectedRowKeys]}`, 'selectedRows: ', selectedRows);
    },
  };

  const columns: ColumnsType<any> = [
    {
      title: '',
      dataIndex: 'image',
      className: 'col-image',
      key: 'image',
      render: (text, record: any) => (
        <>
          {record.image && (
            <div className="imgWrap">
              <Image src={record.image} fallback={defaultImage} preview={false} />
            </div>
          )}
        </>
      ),
    },
    {
      title: 'Product',
      dataIndex: 'name',
      className: 'col-name',
      key: 'name',
      render: (text, record: any) => {
        return (
          <>
            {record.name}
            {record?.sku && (
              <>
                <br />
                <span style={{ color: '#999' }}>SKU: {record?.sku}</span>
              </>
            )}
          </>
        );
      },
    },
    {
      title: 'Base Price (Excl. Tax)',
      dataIndex: 'basePrice',
      className: 'col-basePrice',
      key: 'basePrice',
      render: (text, record: any) => {
        return convertCurrency(record.price, orderInfo.currencyCode);
      },
    },
    {
      title: 'Qty',
      dataIndex: 'qty',
      className: 'col-qty',
      key: 'qty',
      render: (text, record: any, index) => (
        <>
          {!isEdit ? (
            <>{record.qtyOrdered}</>
          ) : (
            <>
              <QtyInput
                index={index}
                value={record.qtyOrdered}
                onChangeQty={value => onChangeQty(value, index)}
                row={record}
                disabled={orderInfo.hasDeliverySlip}
              />
            </>
          )}
        </>
      ),
    },
    {
      title: 'Total (Excl. Tax)',
      dataIndex: 'totalPrice',
      className: 'col-totalPrice',
      key: 'totalPrice',
      render: (text, record: any) => {
        return convertCurrency(record.rowTotal, orderInfo.currencyCode);
      },
    },
    {
      title: 'Action',
      key: 'action',
      dataIndex: 'action',
      className: 'col-action',
      render: (text, record: any) => (
        <Space size="small">
          {record.requiredSerialNumber && (
            <Button type="link" onClick={() => onChangeExpand(record.orderItemId)} className="btnShowPopover">
              <BarcodeOutlined />
            </Button>
          )}

          <DeleteConfirmPopover
            text="Are you sure to delete this item?"
            onClick={() => deleteItem([record.orderItemId])}
            disabled={orderInfo.hasDeliverySlip}
          />
        </Space>
      ),
    },
  ];

  const columnsEdit: ColumnsType<any> = columns.filter(col => {
    if (isEdit) {
      return col.key !== 'action';
    } else {
      return col;
    }
  });

  const showModal = () => {
    // if (_.isEmpty(productList)){
    getProductToAdd();
    // }
    setIsModalVisible(true);
  };
  const hideModal = () => {
    setIsModalVisible(false);
  };

  const onEditQty = () => {
    setIsEdit(!isEdit);
    setExpandRowKeys([]);
  };

  const addProductToOrder = async data => {
    const values = {
      orderId: orderInfo.siteOrderId,
      appKey: appKey,
      product: {
        id: data.id,
        qty: data.qty,
      },
    };

    const resultAddProduct = await dispatch(addProductToOrderEffect(values));

    if (addProductToOrderEffect.fulfilled.match(resultAddProduct)) {
      if (!resultAddProduct.payload.error) {
        notification.success({
          message: '',
          description: 'A new item is added to your order',
          duration: 5,
          className: 'ant-notification-no-title ant-notification-success',
          icon: <CheckCircleFilled />,
        });
        // getOrderDetail();
        form.resetFields();
      } else {
        notification.error({
          message: '',
          description: resultAddProduct?.payload?.error?.message || 'An error has occurred. Please try again.',
          duration: 0,
        });
      }
    }
    hideModal();
  };

  const getProductToAdd = (params?) => {
    const values = {
      orderId: orderInfo.siteOrderId,
      appKey: appKey,
      currencyId: orderInfo.currencyId,
      ...pagination,
      ...params,
    };
    dispatch(searchProductToAddOrder(values));
  };

  const urlInvoice = orderInfo.hasInvoice
    ? `${baseUrl}/ecom/pts/orders/${orderInfo.siteOrderId}/invoice?appKey=${appKey}`
    : '';
  const urlDeliverySlip = orderInfo.hasDeliverySlip
    ? `${baseUrl}/ecom/pts/orders/${orderInfo.siteOrderId}/delivery?appKey=${appKey}`
    : '';

  // const handleChnage = key => {
  //     let k = parseInt(key);
  //     let data = this.state.dataSource;
  //     let value = data[k].expandable;
  //     data[k].expandable = !value;
  // };

  const addRowKey = key => {
    setExpandRowKeys([...expandRowKeys, key]);
  };

  const removeRowKey = key => {
    const rowKeys = expandRowKeys.filter(item => item != key);
    setExpandRowKeys(rowKeys);
  };

  const onChangeExpand = key => {
    if (!expandRowKeys.includes(key)) {
      addRowKey(key);
    } else {
      removeRowKey(key);
    }
  };

  return (
    <>
      <ScrollToTop />
      <div className="openOrderDetail">
        <animated.div style={fadeInUp1}>
          <Row>
            <Col lg={12} span={24}>
              <div className="backToList">
                <Button type="link" className="btnBackToList" onClick={history.goBack}>
                  <LeftOutlined /> Back
                </Button>
              </div>
            </Col>
          </Row>

          <div className="orderInfoBox">
            <Row gutter={24}>
              <Col lg={12} span={24}>
                <div className="infoBox orderInfo">
                  <h3 className="title">Order Info</h3>
                  <div className="boxInfo">
                    <div className="item">
                      <div className="itemLbl">ID:</div>
                      <div className="itemValue">{orderInfo.id}</div>
                    </div>
                    <div className="item">
                      <div className="itemLbl">Create Date:</div>
                      <div className="itemValue">{moment(orderInfo.createdAt).format('DD/MM/YYYY HH:mm')}</div>
                    </div>
                    <div className="item">
                      <div className="itemLbl">Payment method:</div>
                      <div className="itemValue">{orderInfo?.paymentTitle}</div>
                    </div>
                    <div className="item">
                      <div className="itemLbl">Order Status:</div>
                      <div className="itemValue">
                        <SelectConfirm
                          confirmMessage="Are you sure to change order status?"
                          data={orderStatusList}
                          value={orderInfo?.statusId || ''}
                          onConfirm={value => {
                            updateOrderStatus({ statusId: value });
                          }}
                          style={{ width: '200px' }}
                          availableStatus={getAvailableStatusOrder(orderInfo?.statusId, orderStatusList)}
                          // size="large"
                        />
                      </div>
                    </div>
                    <div className="item">
                      <div className="itemLbl">Payment Status:</div>
                      <div className="itemValue">
                        <SelectConfirm
                          confirmMessage="Are you sure to change order status?"
                          data={orderPaymentStatus}
                          value={orderInfo?.paymentStatusId || 0}
                          onConfirm={value => {
                            updateOrderStatus({ paymentStatusId: value });
                          }}
                          style={{ width: '200px' }}
                          // size="large"
                        />
                      </div>
                    </div>
                    <div className="item">
                      <div className="itemLbl">
                        {orderInfo.shippingTitle === 'Collect'
                          ? 'Requested Collection Date'
                          : 'Requested Delivery Date'}
                        :
                      </div>
                      <div className="itemValue">
                        <Popconfirm
                          placement="topLeft"
                          title={'Are you sure to change the Request Delivery Date?'}
                          onConfirm={() => {
                            updateDeliveryDate(isConfirmDeliveryDate);
                            setIsConfirmDeliveryDate('');
                          }}
                          onCancel={() => {
                            setIsConfirmDeliveryDate('');
                          }}
                          okText="Yes"
                          cancelText="No"
                          visible={!!isConfirmDeliveryDate}
                        >
                          <DatePicker
                            value={
                              orderInfo?.requestedDeliveryDate
                                ? moment(new Date(orderInfo?.requestedDeliveryDate), 'DD/MM/YYYY')
                                : null
                            }
                            disabledDate={(current: any) => {
                              return current && current <= moment().startOf('day');
                            }}
                            format={['DD/MM/YYYY', 'DD/MM/YY']}
                            style={{ width: '200px' }}
                            inputReadOnly={true}
                            suffixIcon={<DownOutlined />}
                            placeholder=""
                            onChange={(date: any, dateString) => {
                              setIsConfirmDeliveryDate({ deliveryDate: date.format('YYYY-MM-DD') });
                            }}
                          />
                        </Popconfirm>
                      </div>
                    </div>
                    <div className="item">
                      <div className="itemLbl">Order Reference:</div>
                      <div className="itemValue">
                        <Popconfirm
                          placement="topLeft"
                          title={'Are you sure to change the Order Reference?'}
                          onConfirm={() => {
                            setIsConfirmChangeReference(false);
                            updateOrderReference({ bwxOrderReference: orderReferenceEdit });
                          }}
                          onCancel={() => {
                            setIsConfirmChangeReference(false);
                            setOrderReferenceEdit(orderInfo.bwxOrderReference || '');
                          }}
                          okText="Yes"
                          cancelText="No"
                          visible={isConfirmChangeReference}
                        >
                          <div ref={orderReferenceref} style={{ width: '200px' }}>
                            <Input
                              value={orderReferenceEdit}
                              onChange={(e: any) => {
                                if (e.target.value.length <= 17) {
                                  setOrderReferenceEdit(e.target.value || '');
                                }
                              }}
                              style={{ width: '200px' }}
                              onPressEnter={() => setIsConfirmChangeReference(true)}
                              suffix={
                                <EditOutlined
                                  style={{ cursor: 'pointer' }}
                                  onClick={() => setIsConfirmChangeReference(true)}
                                />
                              }
                            />
                          </div>
                        </Popconfirm>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              {driverInfo?.driverId != 0 && (
                <Col lg={12} span={24}>
                  <div className="infoBox driverInfo">
                    <h3 className="title">Driver Info</h3>
                    <div className="boxInfo">
                      <DriverInfo driverInfo={driverInfo} />
                    </div>
                  </div>
                </Col>
              )}
            </Row>
          </div>
        </animated.div>

        <animated.div style={fadeInUp2}>
          <div className="infoBox orderNote">
            <h3 className="title">Order Note</h3>
            {orderInfo?.orderNoteHistory?.length > 0 && (
              <div className="boxInfo">
                <h4 className="subTitle">Messages</h4>
                <div className="message-wrap">
                  {orderInfo.orderNoteHistory.map((mes: any, index: number) => (
                    <div className="message" key={index}>
                      <div className="message-sender">
                        <div className="message-sender-name">
                          {mes.firstName} {mes.lastName}
                        </div>
                        <div className="message-sender-time">{mes.dateUp}</div>
                      </div>
                      <div className="message-content">{mes.message}</div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </animated.div>

        <animated.div style={fadeInUp2}>
          <div className="infoBox customerInfo">
            <h3 className="title">Customer Details</h3>
            <div className="boxInfo">
              <div className="item customerName">
                <span className="itemLbl">Customer Name:</span>
                <span className="itemValue">{orderInfo?.shopName || ''}</span>
              </div>
              <Row gutter={24}>
                <Col lg={12} span={24}>
                  <div className="subInfoBox invoiceInfo">
                    <h4 className="title">Invoice Address</h4>
                    <div className="subInfoInfo">
                      <Address address={billingAddress} />
                    </div>
                  </div>
                </Col>
                <Col lg={12} span={24}>
                  <div className="subInfoBox shippingInfo">
                    <h4 className="title">Shipping Address</h4>
                    <div className="subInfoInfo">
                      <Address address={shippingAddress} />
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </animated.div>

        <animated.div style={fadeInUp2}>
          <div className="orderSummary">
            <Row>
              <Col lg={12} span={24}>
                <h3 className="title">Order Summary</h3>
              </Col>
              <Col lg={12} span={24}>
                <div className="btnGroupAction">
                  {isEdit ? (
                    <>
                      <Button
                        type="default"
                        size="large"
                        onClick={() => onCancelUpdateQty()}
                        disabled={orderInfo.hasDeliverySlip}
                        className="btnCancel"
                      >
                        Cancel
                      </Button>
                      <Button
                        type="primary"
                        size="large"
                        onClick={() => {
                          onUpdateQty();
                        }}
                        loading={loadingUpdateQty}
                        disabled={orderInfo.hasDeliverySlip}
                        className="btnUpdateQty"
                      >
                        Done
                      </Button>
                    </>
                  ) : (
                    <>
                      <Button
                        type="default"
                        onClick={onEditQty}
                        size="large"
                        disabled={orderInfo.hasDeliverySlip}
                        className="btnClearSearch"
                      >
                        Edit Item
                      </Button>
                      <Button
                        type="primary"
                        size="large"
                        onClick={() => showModal()}
                        disabled={orderInfo.hasDeliverySlip}
                        className="btnAddNewItem"
                      >
                        Add New Item
                      </Button>
                    </>
                  )}
                </div>
              </Col>
            </Row>

            <div className="boxInfo">
              <div className="tableItemOrder-wrap">
                <Table
                  className={`${isEdit || orderInfo.hasDeliverySlip ? 'isEdit' : ''} hasResponsive tableItemOrder`}
                  rowKey={record => record.orderItemId}
                  columns={columnsEdit}
                  dataSource={itemOrderList}
                  rowSelection={{
                    type: 'checkbox',
                    ...rowSelection,
                  }}
                  pagination={false}
                  locale={{
                    emptyText: 'There are no products in this order',
                  }}
                  // expandRowByClick={true}
                  expandIcon={() => null}
                  expandable={{
                    expandRowByClick: false,
                    expandedRowRender: record => (
                      <ProductSerial
                        orderId={orderInfo.siteOrderId}
                        itemId={record.orderItemId}
                        qty={record.qtyOrdered}
                        serialNumbers={record?.serialNumbers}
                        isOpenHasDelivery={orderInfo.hasDeliverySlip}
                        onCloseExpand={() => removeRowKey(record.orderItemId)}
                      />
                    ),
                  }}
                  expandedRowKeys={expandRowKeys}
                  // onChange={onTableChange}
                  // pagination={{
                  //     ...orderPagination,
                  //     ...screenPagination,
                  // }}
                />
              </div>
              <Row>
                <Col lg={12} span={24}>
                  {!isEdit && !_.isEmpty(selectedItem) && (
                    <div className="deleteItems">
                      <Popconfirm
                        title="Are you sure to delete selected items?"
                        onConfirm={() => {
                          deleteItem(selectedItem);
                        }}
                        okText="Yes"
                        cancelText="No"
                        disabled={_.isEmpty(selectedItem) || orderInfo.hasDeliverySlip}
                      >
                        <Button
                          type="primary"
                          size="large"
                          disabled={_.isEmpty(selectedItem) || orderInfo.hasDeliverySlip}
                          className="btnUpdateQty"
                        >
                          Delete Selected Items
                        </Button>
                      </Popconfirm>
                    </div>
                  )}
                </Col>
                <Col lg={12} span={24}>
                  <div className="totalOrderBox">
                    <div className="totalOrderBox-inner">
                      {_.map(totalSummary, (item, index) => {
                        let labelShow = '';
                        if (item.code == 'subtotal') {
                          labelShow = 'Products:';
                        } else if (item.code == 'discount') {
                          labelShow = 'Discount:';
                        } else if (item.code == 'shipping') {
                          labelShow = 'Shipping:';
                        } else if (item.code == 'grand_total') {
                          labelShow = 'Total (excl. Tax):';
                        } else if (item.code == 'tax') {
                          labelShow = 'Taxes:';
                        } else if (item.code == 'grand_total_incl') {
                          labelShow = 'Total (incl. Tax):';
                        }
                        let priceShow = convertCurrency(item.price, orderInfo.currencyCode);
                        // if (item.price === 0){return} //BWORX-811: if price = 0, still display price
                        return (
                          <React.Fragment key={index}>
                            <div className={`lineItem ${item.code}`}>
                              <div className="itemLbl">{labelShow || item.code}</div>
                              <div className="itemValue">{priceShow}</div>
                            </div>
                          </React.Fragment>
                        );
                      })}
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </animated.div>
      </div>

      <Modal visible={isModalVisible} footer={null} closable={false} onCancel={hideModal} width={600}>
        <div className="addProductToOrder">
          <Form
            form={form}
            name="form-update-orders"
            hideRequiredMark
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 20 }}
            labelAlign="left"
            onFinish={addProductToOrder}
          >
            <h2 className="titleForm">Add New Item</h2>
            <Form.Item
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              className="btnContainer"
              label="Choose Your Product"
              name="id"
              rules={[{ required: true, message: 'This field is required!' }]}
            >
              <SelectProduct
                data={productList}
                placeholder="Select product"
                loading={loadingGetProduct}
                isFull={isFull}
                onLoadmore={params => {
                  getProductToAdd({ ...params, pageNumber: paginationGetProduct.pageNumber + 1 });
                }}
                onSearch={params => {
                  getProductToAdd({ ...params, pageNumber: 1 });
                }}
              />
            </Form.Item>

            <Row>
              <Col lg={12} span={24}>
                <Form.Item name="qty" initialValue={1} label="Qty">
                  <InputNumber min={1} />
                </Form.Item>
              </Col>
              <Col lg={12} span={24}>
                <div className="btnGroupFormAddProductOrder">
                  <Button type="default" onClick={hideModal} className="forgotBtn">
                    Cancel
                  </Button>

                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={loadingAddProduct}
                    disabled={loadingAddProduct}
                    className="submitBtn"
                  >
                    Add
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        </div>
      </Modal>
    </>
  );
};

export default DetailOpenOrder;
