import React, {useEffect, useState} from "react";
import { Form, Row, Col, Input, Table, Button, DatePicker, Collapse} from "antd";
import {DesktopOutlined,
    UserOutlined,
    CaretDownFilled,
    EyeTwoTone,
    PrinterOutlined,
    CheckCircleFilled} from "@ant-design/icons";
import {useSpring, animated} from "react-spring";
import {Link} from "react-router-dom";
import moment, { Moment } from 'moment';
import { format, fromUnixTime } from 'date-fns';


import "./style.scss";
import {useSelector} from "react-redux";
import {ColumnsType} from "antd/es/table";
import Space from "antd/lib/space";
import {Tooltip} from "antd";
import {TablePaginationConfig} from "antd/lib/table";
import {useAppDispatch} from "../../../core/app.store";
import {
    getClosedOrderSelector,
    getOrderPaymentSelector,
    getOrderStatusSelector
} from "../../../core/order/selectors";
import {
    getClosedOrderList,
    getOrderPaymentList,
    getOrderStatusList
} from "../../../core/order/effects";
import {convertCurrency} from "../../../utils";
import SelectOption from "../../../components/SelectOption";
import {getDistributorSelector} from "../../../core/admin/selectors";
// import {isDesktop} from "react-device-detect";
import * as RouteConst from "../../../constants/RouteConst";
import {baseUrl} from "../../../services/urlAPI";
import PrintButton from "../../../components/PrintButton";
import {useHistory, useLocation} from "react-router";
import imgView from '../../../assets/images/view.svg';
import _ from "lodash";
import qs from 'query-string';
import ScrollToTop from "../../../components/ScrollToTop";


const { Panel } = Collapse;

const ClosedOrder = () => {

  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const history = useHistory();
    const location = useLocation();

    const locationSearch = location.search;
    const params = qs.parse(locationSearch, { arrayFormat: 'comma',parseNumbers: true });


    const [screenPagination, setScreenPagination] = useState({
        current: params.pageNumber ? params.pageNumber : 1,
        pageSize: localStorage.getItem('pageSizeClosed') || 10,
        position: ['topRight', 'bottomRight'],
        pageSizeOptions: ['10', '20', '50'],
        showSizeChanger: true,
        showTotal: (total, range) => `${range[0]}-${range[1]} of ${total}`,
    });

    const {data: closedOrder, isLoading, pagination: orderPagination} = useSelector(getClosedOrderSelector);
    const {data: orderStatusList, isLoading: loadingGetOrderStatus} = useSelector(getOrderStatusSelector);
    const orderStatusListFilter = orderStatusList.filter(item => item.closed);
    const {data: orderPaymentStatus} = useSelector(getOrderPaymentSelector);
    const {appKey} = useSelector(getDistributorSelector);

  const fadeInUp = {
    delay: 100,
    from: {
      opacity: 0,
      translateY: 80
    },
    to: {
      opacity: 1,
      translateY: 0
    }
  };
  const fadeInUp1 = useSpring(fadeInUp);
  const fadeInUp2 = useSpring({...fadeInUp, delay: 300});

    const showFilter = () => {
        const paramsSearch = _.omit(params, ['fromDate', 'toDate', 'tab']);
        if (!_.isEmpty(paramsSearch) || params.fromDate || params.toDate){
            return true;
        } else {
            return false;
        }
    };

    useEffect(() => {
        if (_.isEmpty(orderStatusList)) {
            dispatch(getOrderStatusList({appKey: appKey}));
        }
        if (_.isEmpty(orderPaymentStatus)) {
            dispatch(getOrderPaymentList({appKey: appKey}));
        }

      //@ts-ignored
        const fromDate = params.fromDate ? moment(params.fromDate) : undefined;
      //@ts-ignored
        const toDate =  params.toDate ? moment(params.toDate) : undefined;

        form.setFieldsValue({
            ...params,
            fromDate: fromDate,
            toDate: toDate
        });

        getClosedOrder(params);
    }, []);

    const getClosedOrder = (params?: any) => {

        const fromDate: Moment = (form.getFieldValue('fromDate'))?.format('YYYY-MM-DD') || '';
        const toDate: Moment = (form.getFieldValue('toDate'))?.format('YYYY-MM-DD') || '';

        const values = {
            ...screenPagination,
            appKey: appKey,
            pageNumber: 1,
            ...form.getFieldsValue(),
            fromDate: fromDate,
            toDate: toDate,
            ...params,
        };
        dispatch(getClosedOrderList(values));
    };

    const addParamsLocation = (param?: any) => {
        const locationSearch = location.search;
        const params = qs.parse(locationSearch, { arrayFormat: 'comma',parseNumbers: true });
        const newParams = qs.stringify({...params, ...param}, { arrayFormat: 'comma' });
        return newParams;
    };


    const onSubmit = values => {
        const fromDate: Moment = (values.fromDate)?.format('YYYY-MM-DD') || '';
        const toDate: Moment = (values.toDate)?.format('YYYY-MM-DD') || '';

        const params = {
            ...values,
            fromDate: fromDate,
            toDate: toDate,
        };

        const newLocation = addParamsLocation(params);
        history.replace({ pathname: location.pathname, search: newLocation });

        getClosedOrder(params);
    };

    const clearSearch = () => {
        form.resetFields();
        history.replace(`${location.pathname}?tab=2`);
        getClosedOrder();
    };

    const onTableChange = (pagination: TablePaginationConfig, filters, sorter) => {
        const values = {
            ...pagination,
            ...filters,
            ...{ pageNumber: pagination.current },
            ...{ sortBy: sorter.column ? sorter.columnKey : '', sortDirection: sorter.order === 'ascend' ? 'asc' : 'desc' },
        };
        setScreenPagination({ ...screenPagination, ...values });
        const newLocation = addParamsLocation({pageNumber: values.current});
        history.replace({ pathname: location.pathname, search: newLocation });
        getClosedOrder(values);
        localStorage.setItem('pageSizeClosed', values.pageSize);
    };

    const columnsData: ColumnsType<any> = [
        {
            title: 'Order Number',
            dataIndex: 'id',
            className: 'col-Id',
            key: 'id',
            fixed: 'left',
            sorter: true,
            // defaultSortOrder: 'descend', // tạm thời comment vì API chưa có sort
        },
        {
            title: 'Created Date',
            dataIndex: 'createdDate',
            className: 'col-createdDate',
            key: 'createdDate',
            sorter: true,
            render: (text, record: any) => (
                <>{record.createdAt ? moment(record.createdAt).format('DD/MM/YYYY HH:mm') : ''}</>
            ),
        },
        {
            title: 'Customer Name',
            dataIndex: 'shopName',
            className: 'col-shopName',
            key: 'shopName',
            sorter: true,
            render: (text, record: any) => (
                <div className="td-shopName">
                    {record.shopName || ''}
                </div>
            ),
            // sorter: true,
        },
        {
            title: 'Total Amount',
            dataIndex: 'grandTotal',
            className: 'col-totalAmount',
            key: 'grandTotal',
            sorter: true,
            render: (value, record) => {
              return (
                <span className="lblPrice">
                    {convertCurrency(record.grandTotal, record.currencyCode)}
                  </span>
              )
            },
        },

        {
            title: 'Payment Status',
            dataIndex: 'paymentStatus',
            className: 'col-role',
            render: (value, record) => (
                <>{record.paymentStatusId == 0 ? "Unknown" : (record?.paymentStatus || '')}</>
            ),
        },
        {
            title: 'Order Status',
            dataIndex: 'status',
            className: 'col-status',
            render: (value, record,index) => (
                <>{record?.status || ''}</>
            ),
        },
        {
            title: 'Action',
            dataIndex: 'action',
            className: 'col-action',
            fixed: 'right',
            width: 100,
            render: (text, record: any) => {
                return (
                    <div className="actionOrderList">
                        <Tooltip title="View Details">
                            <Link onClick={(e)=>e.stopPropagation()}
                                to={RouteConst.CLOSED_ORDER_DETAIL.replace(':orderId', record.siteOrderId)}>
                                <img src={imgView} />
                            </Link>
                        </Tooltip>
                    </div>
                )
            },
        },
    ];

    const onEditRow = record => {
        let pathOrderDetail = `${RouteConst.CLOSED_ORDER_DETAIL}`.replace(':orderId', record.siteOrderId);
        history.push(pathOrderDetail);
    };

  return (
      <>
      <ScrollToTop />
      <div className="openOrderList">
          {/*<animated.div style={fadeInUp1}>*/}
              <Collapse
                  defaultActiveKey={`${showFilter() ? ['1'] : ''}`}
                  expandIconPosition="right">
                  <Panel header="Filters" key="1">
                      <div className="formSearch">
                          <Form
                              layout="vertical"
                              form={form}
                              name="searchForm"
                              onFinish={onSubmit}
                              initialValues={{}}
                          >
                              <Row gutter={24}>
                                  <Col xl={8} md={12} span={24}>
                                      <Form.Item
                                          name="orderId"
                                          label="Order Number">
                                          <Input size="large" />
                                      </Form.Item>
                                  </Col>

                                  <Col xl={8} md={12} span={24}>
                                      <Form.Item
                                          name="shopName"
                                          label="Customer Name">
                                          <Input size="large" />
                                      </Form.Item>
                                  </Col>

                                  <Col xl={8} md={12} span={24}>
                                      <div className="dateFromTo" >
                                          <Form.Item label="Date Range" name="fromDate" className="flexCol">
                                              <DatePicker
                                                  suffixIcon={<CaretDownFilled />}
                                                  size="large"
                                                  showToday={false}
                                                  clearIcon={false}
                                                  dropdownClassName="quote-picker-dropdown"
                                                  className="quote-picker-request"
                                                  inputReadOnly
                                                  placeholder=""
                                                  format="DD/MM/YYYY"
                                                  // @ts-ignore
                                                  disabledDate={current => {
                                                      return form.getFieldsValue().toDate
                                                          ? current > moment(form.getFieldsValue().toDate).endOf('day')
                                                          : current > moment(new Date()).endOf('day');
                                                  }}
                                              />
                                          </Form.Item>
                                          <div className="space-to-request">
                                              <div>To</div>
                                          </div>

                                          <Form.Item label=" " name="toDate" className="flexCol">
                                              <DatePicker
                                                  suffixIcon={<CaretDownFilled />}
                                                  size="large"
                                                  showToday={false}
                                                  clearIcon={false}
                                                  dropdownClassName="quote-picker-dropdown"
                                                  className="quote-picker-request"
                                                  inputReadOnly
                                                  placeholder=""
                                                  format="DD/MM/YYYY"
                                                  // @ts-ignore
                                                  disabledDate={current => {
                                                      return form.getFieldsValue().fromDate
                                                          ? (current < moment(form.getFieldsValue().fromDate).startOf('day') || current > moment(new Date()).endOf('day'))
                                                          : current > moment(new Date()).endOf('day');
                                                  }}
                                              />
                                          </Form.Item>
                                      </div>
                                  </Col>

                                  <Col xl={8} md={12} span={24}>
                                      <Form.Item
                                          name="orderStatus"
                                          label="Order Status">
                                          <SelectOption data={orderStatusListFilter}
                                                        mode="multiple"
                                                        showArrow
                                                        maxTagCount={'responsive'}
                                                        size="large" />
                                      </Form.Item>
                                  </Col>

                                  <Col xl={8} md={12} span={24}>
                                      <Form.Item
                                          name="paymentStatus"
                                          label="Payment Status">
                                          <SelectOption data={orderPaymentStatus}
                                                        mode="multiple"
                                                        showArrow
                                                        maxTagCount={'responsive'}
                                                        size="large" />
                                      </Form.Item>
                                  </Col>
                                  <Col xl={8} md={12} span={24}>
                                      <div className="btnGroupSearch">
                                          <Form.Item className="btnContainer">
                                              <Button type="default"
                                                      onClick={()=>clearSearch()}
                                                      size="large"
                                                      className="btnClearSearch">Clear All</Button>

                                              <Button type="primary" htmlType="submit" size="large" className="submitBtn">
                                                  Search
                                              </Button>
                                          </Form.Item>
                                      </div>

                                  </Col>
                              </Row>

                          </Form>
                      </div>
                  </Panel>
              </Collapse>

          {/*</animated.div>*/}


          {/*<animated.div style={fadeInUp2}>*/}
              <div className="wrapTable">

              <div className="wrapTableInner">
                  <Table className="hasResponsive tableOrderOpen"
                         rowKey={record => record.siteOrderId}
                         // size={isDesktop ? "large" : 'middle'}
                         columns={columnsData}
                         dataSource={closedOrder}
                         onChange={onTableChange}
                         scroll={{ x: 1000 }}
                         tableLayout="auto"
                         pagination={{
                             ...orderPagination,
                             ...screenPagination,
                         }}
                         onRow={record => {
                             return {
                                 onClick: () => {
                                     onEditRow(record);
                                 },
                             };
                         }}
                         locale={{
                             emptyText: 'No orders found'
                         }}
                  />
              </div>
              </div>
          {/*</animated.div>*/}
    </div>

    </>
  );
};

export default ClosedOrder;
