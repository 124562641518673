import React, { useEffect } from 'react';
import { Select } from 'antd';
import {SelectProps} from "antd/lib/select";
import _ from "lodash";

interface Props extends SelectProps<any> {
    data: any,
    availableStatus?: any,
};
const { Option } = Select;
const SelectOption = ({ data, availableStatus, ...rest }: Props) => {
  useEffect(() => {
    // console.log('data-option', data);
    // console.log('value-option', value);
  }, [data]);

  return (
    <Select {...rest}>
      {data.map(d => {
          let isDisabled = false;
          if (availableStatus){
              if (_.includes(availableStatus, d.id)){
                  isDisabled = false;
              } else {
                  isDisabled = true;
              }
          }
          return (
              <Option key={d.id} value={d.id} disabled={isDisabled}>
                  {d.name}
              </Option>
          )
      })}
    </Select>
  );
};

export default SelectOption;
