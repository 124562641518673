export const ApiConstants = {
  ADMIN_LOGIN_URL: '/ecom/pts/accounts/employee/login',
  GET_OPEN_ORDER_LIST: '/ecom/pts/orders',
  GET_CLOSED_ORDER_LIST: '/ecom/pts/orders',
  GET_ORDER_STATUS_LIST: '/ecom/pts/orders/status?appKey=%s',
  GET_ORDER_PAYMENT_LIST: '/ecom/pts/orders/payment-status?appKey=%s',
  UPDATE_ORDER: '/ecom/pts/orders/status?appKey=%s',
  UPDATE_DELIVERY_DATE: '/ecom/pts/orders/%s/delivery-date',
  UPDATE_ORDER_REFERENCE: '/ecom/pts/orders/%s/order-reference',
  GET_ORDER_OPEN_DETAIL: '/ecom/pts/orders/%s?appKey=%s',
  GET_CLOSED_ORDER_DETAIL: '/ecom/pts/orders/%s?appKey=%s',
  UPDATE_QTY_ITEM_ORDER_DETAIL: '/ecom/pts/orders/%s/items/qty',
  DELETE_PRODUCT_ORDER_DETAIL: '/ecom/pts/orders/%s/items',
  SEARCH_PRODUCT_TO_ADD_ORDER: '/ecom/pts/orders/%s/product/search',
  ADD_PRODUCT_TO_ORDER: '/ecom/pts/orders/%s/items',
  ADD_SERIALS_OF_ORDER_ITEMS: '/ecom/pts/orders/%s/serials',
};
export default ApiConstants;
