import React, {useEffect} from "react";
import {Form, Input, Button, Typography, Image, Alert} from 'antd';
import {useSelector, useDispatch} from "react-redux";
import {Redirect, useHistory} from "react-router-dom";
import {CSSTransition} from "react-transition-group";
import {useSpring, animated} from "react-spring";
import get from 'lodash/get';

import "./style.scss";
import logo from "../../assets/images/logoDistell.png";
import {clearState} from "../../core/admin/admin.slice";
import {login} from "../../core/admin/effects/admin.effects";
import {RootState} from "../../core/app.store";
import _ from "lodash";

const {Title} = Typography;

const LoginPage = () => {
    const [form] = Form.useForm();
    const history = useHistory();

    const isLoading = useSelector((state: RootState) => get(state.admin, 'isLoading', false));
    const isSuccess = useSelector((state: RootState) => get(state.admin, 'isSuccess', false));
    const errorMessage = useSelector((state: RootState) => get(state.admin, 'errorMessage', ''));
    const loginUser = useSelector((state: RootState) => get(state.admin, 'loginUser', {}));

  const dispatch = useDispatch();
  const fadeInUp = {
    delay: 100,
    from: {
      opacity: 0,
      translateY: 80
    },
    to: {
      opacity: 1,
      translateY: 0
    }
  };
  const fadeInUp1 = useSpring(fadeInUp);
  const fadeInUp2 = useSpring({...fadeInUp, delay: 300});

  const onFinish = (data) => {
    dispatch(clearState());
    dispatch(login(data));
  };

  useEffect(() => {
    dispatch(clearState());
  }, []);

  useEffect(() => {
    if (!_.isEmpty(loginUser)){
        history.push('/');
    }
  }, [loginUser]);

  useEffect(() => {
    if (isSuccess) {
      history.push('/');
        dispatch(clearState());
    }
  }, [isSuccess]);

  return (
      <Form
          layout="vertical"
          form={form}
          requiredMark={false}
          name="loginForm"
          onFinish={onFinish}
      >
          <animated.div style={fadeInUp1}>
              <div style={{textAlign: 'center'}}><Image width={300} src={logo} preview={false}/></div>
          </animated.div>

          <animated.div style={fadeInUp2}>
              <Title level={2}>Login</Title>


              <CSSTransition
                  in={errorMessage!=''}
                  timeout={300}
                  unmountOnExit
                  classNames="alert">
                  <Alert message={errorMessage} type="error" className="errorMessage"/>
              </CSSTransition>

              <Form.Item
                  label="Email Address"
                  name="username"
                  rules={[
                      {required: true, message: "Please input your email address!"}
                  ]}
              >
                  <Input/>
              </Form.Item>

              <Form.Item
                  label="Password"
                  name="password"
                  rules={[
                      {required: true, message: "Please input your password!"}
                  ]}
              >
                  <Input.Password/>
              </Form.Item>

              <Form.Item className="btnContainer">
                  {/*<Button type="link"*/}
                          {/*className="forgotBtn"*/}
                          {/*disabled={isLoading} >Forgot Your Password?</Button>*/}

                  <Button type="primary"
                          htmlType="submit"
                          disabled={isLoading}
                          loading={isLoading}
                          size="large"
                          className="submitBtn">Login</Button>
              </Form.Item>
          </animated.div>
      </Form>
      )

}

export default LoginPage;
