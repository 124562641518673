import Order from "../../pages/Order";
import * as RouteConst from "../../constants/RouteConst";
import ClosedOrder from "../../pages/Order/CloseOrder";
import OrderOpen from "../../pages/Order/OrderOpen";
import DetailOpenOrder from "../../pages/Order/OrderOpen/OrderDetail";
import ClosedOrderDetail from "../../pages/Order/CloseOrder/ClosedOrderDetail";

export default [
    {
        component: Order,
        path: RouteConst.ORDER_MANAGER,
        name: 'Order',
        exact: true,
    },
    {
        component: OrderOpen,
        path: RouteConst.ORDER_LIST_OPEN,
        name: 'Order Open',
        isMenu: true,
        exact: true,
    },
    {
        component: ClosedOrder,
        path: RouteConst.ORDER_LIST_CLOSE,
        name: 'Order Close',
        exact: true,
    },
    {
        component: DetailOpenOrder,
        path: RouteConst.ORDER_OPEN_DETAIL,
        name: 'Order Open Detail',
        exact: true,
    },
    {
        component: ClosedOrderDetail,
        path: RouteConst.CLOSED_ORDER_DETAIL,
        name: 'Closed Order Detail',
        exact: true,
    },
];