import React, { useEffect } from 'react';
import { Switch, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import { RootState } from '../../core/app.store';
import PrivateLayoutRoute from "./PrivateLayoutRoute";
import get from "lodash/get";
import {getMenuFromConfig, getRoutesFromConfig} from "../../utils";
import PrivateRoutesConfig from '../config/PrivateRoutesConfig';
import {Layout} from 'antd';
import './style.scss';
import * as RouteConst from "../../constants/RouteConst";


type RouteConfig = {
  path: string;
  [key: string]: any;
};

const PrivateRoutes = () => {
  // const dispatch = useAppDispatch();

    const loginUser = useSelector((state: RootState) => get(state.admin, 'loginUser', {}));

  let allowedRoutes: Array<RouteConfig> = [];
  let routerMenu: any = [];

  if (!_.isEmpty(loginUser)) {
      allowedRoutes = getRoutesFromConfig(PrivateRoutesConfig);
      // routerMenu = getMenuFromConfig(PrivateRoutesConfig);
  } else {
    return <Redirect to={RouteConst.LOGIN_URL} />;
  }

  return (
    <>
      {allowedRoutes.length && (
        <Layout className="private-layout" hasSider>
          {/*<SideBar routes={routerMenu} />*/}

          <Switch>
            {allowedRoutes.map(item => {
              return (
                <PrivateLayoutRoute exact={item.exact} path={item.path} component={item.component} key={item.path} />
              );
            })}

            <Redirect to={RouteConst.LOGIN_URL} />
          </Switch>
        </Layout>
      )}
    </>
  );
};

export default PrivateRoutes;
