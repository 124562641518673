import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { add, formatISO } from 'date-fns';
import {GenericObject} from "../../@types/common";
import {login} from './effects/admin.effects';


const initState = {
     isLoading: false,
    isSuccess: false,
    isError: false,
    errorMessage: "",
    loginUser: {} as GenericObject,
};
const adminSlice = createSlice({
  name: 'admin',
  initialState: initState,
    reducers: {
        clearState: (state) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.isError = false;
            state.errorMessage = '';
            return state;
        },
        logout(state) {
            localStorage.clear();
            // removeHeaderAuthorization();
            return initState;
        },
    },
  extraReducers: builder => {
    builder
      .addCase(login.fulfilled, (state, action) => {
          state.isLoading = false;
          if (!action.payload.error){
              state.isSuccess = true;
              state.loginUser = action.payload;
          } else {
              state.errorMessage = action.payload?.error?.message;
          }
      })
      .addCase(login.pending, state => {
        state.isLoading = true;
      })
      .addCase(login.rejected, (state, action) => {
          console.log(action.payload);
          state.isLoading = false;
          state.isError = true;
          // state.errorMessage = action.payload.message;
      });
  },
});
export const {clearState, logout} = adminSlice.actions;
export default adminSlice.reducer;
