import React, {useEffect, useState} from 'react';
import {Select, Popover, PopoverProps, Button, Form, Input, Row, Col, notification} from 'antd';
import {BarcodeOutlined, CheckCircleFilled} from "@ant-design/icons";
import _ from 'lodash';
import './styles.scss';
import {addProductToOrderEffect, addSerialsOfOrderItems, getOrderOpenDetail} from "../../core/order/effects";
import {async} from "q";
import {useDispatch, useSelector} from "react-redux";
import {getDistributorSelector} from "../../core/admin/selectors";
import {RootState} from "../../core/app.store";
import {getSafeValue} from "../../utils";

interface Props {
    orderId?: any,
    itemId?: any,
    qty: number,
    serialNumbers: [],
    onCloseExpand?: Function,
    isClosed?: boolean,
    isOpenHasDelivery?: boolean
};

const ProductSerial = ({orderId, itemId, qty, serialNumbers, onCloseExpand, isClosed, isOpenHasDelivery}: Props) => {

    const [form] = Form.useForm();
    const dispatch = useDispatch();

    const {appKey} = useSelector(getDistributorSelector);
    const isLoading = useSelector((state: RootState) => _.get(state.order.serialsOfOrderItems, 'isLoading', false));

    // useEffect(() => {
    //     form.setFieldsValue({
    //         itemId: itemId,
    //     });
    // }, [itemId]);

    useEffect(() => {
        setValuesForm();
    }, [serialNumbers]);


    const getOrderDetail = () => {
        const params = {
            appKey: appKey,
            orderId: orderId
        };
        dispatch(getOrderOpenDetail(params));
    };

    const setValuesForm = () => {
        if (serialNumbers){
            form.setFieldsValue({
                serialNumbers: serialNumbers,
            });
        }
    };


    const onSubmit = async (data) => {

        const values = {
            orderId: orderId,
            appKey: appKey,
            items: [{
                itemId: itemId,
                serialNumbers: data.serialNumbers
            }]
        };

        const resultAddSerials: any = await dispatch(addSerialsOfOrderItems(values));


        if (addSerialsOfOrderItems.fulfilled.match(resultAddSerials)) {
            if (resultAddSerials?.payload?.error?.code == 0 && resultAddSerials?.payload?.error?.message == 'OK') {
                //BWORX-703: Should expect the error with code = 0 and message = OK
                notification.success({
                    message: '',
                    description: 'The serial numbers are added successfully',
                    duration: 5,
                    className: 'ant-notification-no-title ant-notification-success',
                    icon: <CheckCircleFilled/>,
                });
                if (_.isFunction(onCloseExpand)){
                    onCloseExpand();
                }
                getOrderDetail();
            } else {
                notification.error({
                    message: '',
                    description: resultAddSerials?.payload?.error?.message || 'An error has occurred. Please try again.',
                    duration: 0,
                });
            }
        }
    };

    const onKeyPress = (event) => {
        if (event.keyCode === 13) {
            const formTarget = event.target.form;
            const index = Array.prototype.indexOf.call(formTarget, event.target);
            if ((index+1) < qty){
                formTarget.elements[index + 1].focus();
            }
            event.preventDefault();
        }
    };

    const closeExpand = () => {
        if (_.isFunction(onCloseExpand)){
            onCloseExpand();
        }
        setValuesForm();
    };

    const formAddBarCode = (
        <div className="formSubmitBarcode">
            <Form
                form={form}
                name={`orderItemId${itemId}`}
                onFinish={onSubmit}
            >
                <h3 className="titleForm">Serial Number</h3>
                <Row gutter={20}>
                {_.times(qty, index => {
                    return (
                        <Col xl={qty == 1 ? 24 : 6} lg={qty == 1 ? 24 : 8} md={qty == 1 ? 24 : 12} span={24}>
                            <Form.Item
                                label={`${index+1}`}
                                colon={false}
                                name={['serialNumbers', index]}
                                // rules={[
                                //     {required: true, message: "This field is required"}
                                // ]}
                                rules={[
                                    ({ getFieldsValue }) => {
                                        const serialNumbers = getSafeValue(getFieldsValue(), 'serialNumbers', []);
                                        return {
                                            validator(rule, value) {
                                                const serialNumbersFilter = serialNumbers.filter(item => {
                                                    if (index != serialNumbers.indexOf(item)) {
                                                        return item;
                                                    }
                                                });
                                                if (serialNumbersFilter.includes(value)) {
                                                    return Promise.reject('Duplicate serial number');
                                                }
                                                return Promise.resolve();
                                            },
                                        };
                                    },
                                ]}
                            >
                                <Input
                                    autoFocus={index==0}
                                    onKeyDown={(e)=> onKeyPress(e)}
                                    maxLength={250}

                                />
                            </Form.Item>
                        </Col>
                    )
                })
                }
                </Row>

                <Form.Item className="fieldSubmit">
                    <Button type="default"
                            onClick={closeExpand}
                            tabIndex={-1}>Cancel</Button>
                    <Button type="primary"
                            htmlType="submit"
                            loading={isLoading}
                            className="submitBtn">
                        Save
                    </Button>
                </Form.Item>

            </Form>
        </div>
    );

    const preventClick = (e) => {
        e.stopPropagation();
    };

    const showBarCode = (
        <div className="listBarCode">
            <h3 className="titleForm">Serial Number</h3>
            <div className="itemsField">
                {serialNumbers.length > 0 ?
                <Row gutter={20}>
                    {_.map(serialNumbers, (serialNumber, index) => {
                        return (
                            <Col xl={6} lg={8} md={12} span={24}>
                                <div className="number">
                                    <span className="lblItem">{`${index + 1}.`}</span>
                                    <span className="lblValue">{serialNumber}</span>
                                </div>
                            </Col>
                        )
                    })
                    }
                </Row>
                    : <div className="noSerialNumber">No serial numbers available.</div>
                }

            </div>
            {isOpenHasDelivery &&
            <div className="actionFields">
                <Button type="default"
                        onClick={closeExpand}>
                    Close</Button>
            </div>
            }

        </div>
    );

    return (
        <>
        <div className="productSerial" onClick={preventClick}>
            {isClosed || isOpenHasDelivery ? showBarCode : formAddBarCode}
        </div>
        </>
    );
};

export default ProductSerial;
