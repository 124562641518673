import React, { useEffect } from 'react';

interface Props {
    address: any;
};

const Address = ({address}: Props) => {


    if (!address) {return null}
  return (
      <>
          {address?.street &&
          <><span className="street">{address?.street}</span>&nbsp;<br/></>
          }
          {address?.street2 &&
          <><span className="street2">{address?.street2}</span>&nbsp;<br/></>
          }
          {(address?.city || address?.postcode) &&
          <><span className="postcode">{address?.postcode}</span>,&nbsp;
              <span className="city">{address?.city}</span><br/></>
          }
          {(address?.region || address?.countryName) &&
          <>
              {address?.region && <><span className="region">{address?.region}</span>,&nbsp;</> }
              <span className="countryName">{address?.countryName}</span><br/></>
          }
          {address?.telephone &&
          <><span className="telephone">{address?.telephone}</span></>
          }
      </>
  );
};

export default Address;
