import { createAsyncThunk } from '@reduxjs/toolkit';
import AdminServices from '../services';
import {setApiHeader} from "../../../services/api";


export const login = createAsyncThunk('admin/login', async (params: { username, password }, { dispatch, rejectWithValue }) => {
  try {
    const result = (await AdminServices.loginAPI(params)).data;
    if (!result.error){
        let kpData = {
            keypairData: result.keypairData,
            keypairExpiration: result.keypairExpiration,
            keypairVersion: result.keypairVersion,
        };
        localStorage.setItem('kpData', JSON.stringify(kpData));
        await setApiHeader();

    }
    return result;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

