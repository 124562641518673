import { combineReducers } from 'redux';
import adminSlice from './admin/admin.slice';
import orderSlice from './order/order.slice';

export const rootReducer = combineReducers({
  admin: adminSlice,
  order: orderSlice,
});

export default rootReducer;
